import React from 'react';
import './ErrorPage.css';

const ErrorPage = ({ message }) => {
  return (


    <div className="error-container">

    <div className='errorTextContainer'>
      <h1>Whoops !</h1>
      <p>{message || 'An unexpected error occurred.'}</p>
 
  
    </div>
    <div className='errorImageContainer'>
      <img src='images/404 Image.png' height="120%" width="100%"></img>
      </div>
</div>
  );
};

export default ErrorPage;
import { Modal, ButtonToolbar, Button, Placeholder } from "rsuite";
import React, { useState } from "react";
import { useSessionManager } from "./SessionManagerProvider";

const MobileDevice = () => {
 
  const {
    mobileError,setIsMobileError
  } = useSessionManager();
  const handleClose = () => {
    
    setIsMobileError(false);
  }



  return (
  
      <Modal size="calc(100% - 20px)"  open={mobileError}>
       
        <Modal.Body>

        <div
            style={{
              padding: 10,
              background: "white",

              borderRadius: 20,
              overflow: "hidden",

              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
                display: "flex",
              }}>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
               
                }}>
                <div style={{textAlign: "center" ,display:'flex',flexDirection:'column'}}>
                 
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 26,
                      fontFamily: "Inter",
                      fontWeight: "700",
                      wordWrap: "break-word",
                      marginBottom:'10px'
                    }}>
                    Heads up!
                  </span>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                      For a better experience,please use a computer. Adeptia Labs works best for desktop users.
                 
                  </span>
                </div>
              </div>
            </div>
          </div>

        {/* <div style={{
        display:"flex",
        justifyContent:'center',
        alignItems:'center',
        height:"100vh"
    }}>

   
    <div
      style={{
        width: 353,
        height: 227,
        padding: 50,
        background: "white",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: 20,
        overflow: "hidden",
        border: "4px #EDEDED solid",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        display: "inline-flex",
      }}>
      <div
        style={{
          alignSelf: "stretch",
          height: 127,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 15,
          display: "flex",
        }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            display: "inline-flex",
          }}>
          <div
            style={{
              textAlign: "center",
              color: "#0A0F0A",
              fontSize: 30,
              fontFamily: "Inter",
              fontWeight: "700",
              wordWrap: "break-word",
            }}>
            Heads up!
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            display: "inline-flex",
          }}>
          <div
            style={{
              flex: "1 1 0",
              textAlign: "center",
              color: "black",
              fontSize: 16,
              fontFamily: "Inter",
              fontWeight: "400",
              wordWrap: "break-word",
            }}>
            Experience Adeptia Labs at its best by visiting on a desktop. Our
            features are designed for desktop users in mind!
          </div>
        </div>
      </div>
    </div>
    </div> */}
        </Modal.Body>
      </Modal>
    
  );
};




export default MobileDevice;

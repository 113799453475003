import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";
import React from "react";
import { createStyles } from '@mantine/core';
import { useState,useEffect } from "react";
import { URL } from "../UrlConstants";
import axios from 'axios';
import {  toast } from 'react-toastify';
import { ColorRing } from  'react-loader-spinner'
import { dateParser } from "./Helper";
import posthog from 'posthog-js';
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import './Profile.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  container : {
    display:"flex",
    justifyContent:"center"
  },
  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export const  Profile = () => {
    const { classes } = useStyles();
    const { user,getAccessTokenSilently,logout } = useAuth0();
    const [dialogopen, setDialogOpen] = React.useState(false);
   
    const handleDialogClose = () => {
      posthog.capture("Deleting User Account")
      setDialogOpen(false);
    };

  const[loader,setLoader] = useState(false);
  const[deleteLoad,setDeleteLoad] = useState(false);

    const [userData, setUserData] = useState({
      maxUsage:0,
      currentUsage:0,
      firstName:"",
      lastLoginOn:"",
      dailyQuota:0,
      counterValue:0,
     
    });

    const [usage, setUsage] = useState({
      knowledgemaxcount:"",
      knowledgecurrentcount:""
    });

    const fetchKnowledgbaseUsage = async () => {
      
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
          
          `${URL.KNOWLEDGEBASE}`,
          { method: "GET",
            headers:{
              "Authorization":"Bearer "+accessToken
            }
        }
        )
        let responseData = await response
        if(responseData.status < 400){
          responseData = await response.json();
          const usage = responseData.knowledgebase.filter((item) => {
            if(item.createdBy != user.email){
              return false
            }
            else {
              return true
            }
          });
          setUsage({
            knowledgemaxcount:responseData.count,
            knowledgecurrentcount:usage.length
          })
         
        }
        else if(responseData.status >= 400 && responseData.status < 500 ){
          logout({
            returnTo: window.location.origin + '/#session-expired', // Specify the logout URL here
          });
        }
     
    
      }

      const deleteUser = async () => {
        setDeleteLoad(true);
        posthog.capture("Updating Profile");
        const accessToken = await getAccessTokenSilently();
        try{
          const response = await fetch(
            
            `${URL.DELETEUSER}`,
            { method: "DELETE",
              headers:{
                "Authorization":"Bearer "+accessToken
              }
          }
          )
          let responseData = await response
          if(responseData.status < 400){
            setDeleteLoad(true);
            logout({ logoutParams: { returnTo: window.location.origin } });
          }
          else if(responseData.status === 401){
            setDeleteLoad(false);
            
          }
          else{
            setDeleteLoad(false);
          }
       
        }
        catch(Exception){
          setDeleteLoad(true);
          // setLoading(false);
        }
      }
      
      


    const fetchdata = async () => {
      setLoader(true);
      posthog.capture("Updating Profile");
      const accessToken = await getAccessTokenSilently();
      try{
        const response = await fetch(
          
          `${URL.GETUSER}`,
          { method: "GET",
            headers:{
              "Authorization":"Bearer "+accessToken
            }
        }
        )
        let responseData = await response
        if(responseData.status < 400){
          const jsonValue = await response.json();

          fetchKnowledgbaseUsage();

          
          setUserData(jsonValue);
          setLoader(false);
        }
        else if(responseData.status === 401){
          setLoader(false);
          logout({
            returnTo: window.location.origin + '/#session-expired', // Specify the logout URL here
          });
        }
     
      }
      catch(Exception){
        setLoader(false);
        // setLoading(false);
      }
      }
    useEffect (() => {
      fetchdata();  
        },[])

    posthog.capture("My Profile");

    const updateUser = async() =>{
      setLoader(true);
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(URL.MANUALUPDATE, userData, {
        headers: {
          "Authorization":"Bearer "+accessToken
        }
      }).then((response) => {
       
        setUserData(response.data);
        setLoader(false);
        toast.success("User Updated", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 7000, // Close the toast after 3 seconds
        });

       
                  }).catch(error => {
                    setLoader(false);
                    toast.error("Error while updating user", {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 7000, // Close the toast after 3 seconds
                    })
                  });
    }
  

    return(

      <div className="main-content">
       {loader && <div className="loader-overlay">
            <ColorRing
              visible={loader}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
            </div>}

            
      <Dialog
        open={dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete your account  ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Deleting your account will permanently remove your entire knowledgebase. This action cannot be undone, do you still want to proceed ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>

        <Button onClick={handleDialogClose} disabled={deleteLoad}>Cancel</Button>

        <LoadingButton 
          loading={deleteLoad}
          // loadingPosition="end"
          autoFocus
          onClick={deleteUser}
              >
                Confirm
          
            
          </LoadingButton>

        </DialogActions>
      </Dialog>

    {/* Page content */}
    <div className="container-fluid mt--79">
      <div className="row">
        <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div className="card card-profile shadow" style={{marginTop: "8vh"}}>
            <div className="row justify-content-center">
              <div className="col-lg-3 order-lg-2">
                <div className="card-profile-image">
                  <a href="#">
                    <img src={user.picture} className="rounded-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div className="d-flex justify-content-between">
                
              </div>
            </div>
            <div className="card-body pt-0 pt-md-4">
             
              <div className="text-center">
                <h3 style={{fontSize:"20px"}}>
                {userData.firstName}
                </h3>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2"></i>{user.email}
                </div>
                <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2"></i>Daily Quota Consumed  - {userData.dailyQuota !== 0 ? ((userData.counterValue / userData.dailyQuota) * 100) : '0'}%
                </div>
                <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2"></i>Knowledgebase Quota - {usage.knowledgecurrentcount !== 0 ? ((usage.knowledgecurrentcount / usage.knowledgemaxcount) * 100): '0'}%
                </div>
               
             
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 order-xl-1">
          <div className="card bg-secondary shadow">
            <div className="card-header bg-white border-0">
              <div className="row align-items-center">
                <div className="col-8">
                  <h3 className="mb-0">My Account</h3>
                </div>
                <div className="col-4 text-right">
                <Button variant="outlined" color="error" size="small" style={{marginRight:"10px"}}
                  onClick={() => setDialogOpen(true)}
                >
                  <DeleteIcon />
                    Delete My Account
                  </Button>

                  <Button variant="contained" size="small" onClick={updateUser} style={{backgroundColor:"#88AC2F"}}>
                  <UpgradeIcon />
                    Update
                  </Button>
                 
                </div>
              </div>
            </div>
            <div className="card-body">
              <form>
                <h6 className="heading-small text-muted mb-4">User information</h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" htmlFor="input-username">Name</label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="Username" value={userData.firstName} onChange={(e) => {
                          setUserData({...userData,firstName:e.target.value})
                        }} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" htmlFor="input-email">Email address</label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="jesse@example.com" readOnly value={user.email} />
                      </div>
                    </div>
                  </div>
                  {/* ... [rest of the form fields remain unchanged] ... */}
                </div>
              </form>

              <form>
                <h6 className="heading-small text-muted mb-4">Consumption</h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label">Daily Quota for Extract Data</label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="Max Consumption Allowed" value={userData?.dailyQuota} readOnly/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" >Current Usage</label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" value={userData?.counterValue} readOnly />
                      </div>
                    </div>
                  </div>
                  {/* ... [rest of the form fields remain unchanged] ... */}
                </div>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label">Max Document Allowed for Knowledgebase</label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" value={usage.knowledgemaxcount} readOnly/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" >Current Usage</label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" value={usage.knowledgecurrentcount} readOnly />
                      </div>
                    </div>
                  </div>
                  {/* ... [rest of the form fields remain unchanged] ... */}
                </div>

                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label">Max Document Size </label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" value={10+" MB"} readOnly/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" >Supported Formats</label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" value="pdf,csv,txt,jpeg,jpg,png" readOnly />
                      </div>
                    </div>
                  </div>
                  {/* ... [rest of the form fields remain unchanged] ... */}
                </div>
              </form>

              <form>
                <h6 className="heading-small text-muted mb-4">Auth Information</h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label">Auth Provider</label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="Provider" value={user.sub} readOnly/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" >Last Login On</label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" value={dateParser(userData.lastLoginOn)} readOnly />
                      </div>
                    </div>
                  </div>
                  {/* ... [rest of the form fields remain unchanged] ... */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
       )
 };


 export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading />,
  });
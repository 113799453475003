import {
  createStyles,
  Text,
  Avatar,
  Group,
  TypographyStylesProvider,
  Paper,
  Button,
  Notification,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import GavelIcon from "@mui/icons-material/Gavel";
import RouteIcon from "@mui/icons-material/Route";
import { Footer } from "./Footer";
import posthog from "posthog-js";
import Joyride, { STATUS } from "react-joyride";
import { useSessionManager } from "../SessionManagerProvider";
import { UpdateUser } from "./Helper";
import "./dashboard.css";

const useStyles = createStyles((theme) => ({
  comment: {
    padding: "20px 24px",
    width: "30%",
    margin: "0.5%",
  },
  exploreBtn: {
    alignSelf: "center",
    marginTop: 10,
  },
  body: {
    paddingLeft: 54,
    paddingTop: theme.spacing.sm,
    fontSize: theme.fontSizes.sm,
  },

  content: {
    "& > p:last-child": {
      marginBottom: 0,
    },
    "@media (max-width: 420px)": {
      comment: {
        width: "100%",
      },
    },
  },
}));

const iconStyle = {
  fontSize: 40, // Adjust the size as per your requirement
};

function Dashboard() {
  const { classes } = useStyles();
  const { user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [showNotification, setShowNotification] = useState(false);
  const {
    loggedInUser,
    setLoggedInUser,
    disclaimerDrawer,
    setDisclaimerDrawer,
    tourStyles,
    runTourDashboard,
    setRunTourDashboard,
    setIsMobileError
  } = useSessionManager();

  useEffect(() =>{
    setIsMobileError(false);
  },[])

  const steps = [
    {
      content: (
        <h7>
          Welcome to Adeptia Labs, where innovation and integration converge!
          Try new features from Adeptia. These features are available in Adeptia
          Connect product with more capabilities and are usable as part of
          runtime data processing.
        </h7>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      title: "Lets Start !!!",
      target: "body",
    },
    {
      content: (
        <h7>
          Transform your documents into a dynamic source of data with Adeptia
          AI. Provide your information in uploaded document and see Generative
          AI deliver insights based on your prompts.
        </h7>
      ),
      placement: "bottom",
      target: "#dash-1",
      title: "AI Knowledge Base",
    },
    {
      content: (
        <h7>
          Integrate data from your unstructured sources by easily and accurately
          extracting structured data in JSON or CSV formats with Adeptia AI.
        </h7>
      ),
      placement: "bottom",
      target: "#dash-2",
      title: "Structured Data from Unstructured files",
    },
  ];

  const updateTour = async (value) => {
    if (value.status === "finished") {
      let tourUpdate = loggedInUser.tourView.split(":");
      tourUpdate = "true:" + tourUpdate[1] + ":" + tourUpdate[2];

      let userTour = { ...user, tourView: tourUpdate };

      const accessToken = await getAccessTokenSilently();
      const loguser = await UpdateUser(userTour, accessToken);
      setLoggedInUser(loguser);
    }
  };

  // useEffect(() => {
  //   if (loggedInUser !== null) {
  //     setRunTourDashboard(
  //       loggedInUser.tourView.split(":")[0] === "false" ? true : false
  //     );
  //   }
  // }, [loggedInUser]);

  return (
    <div style={{
      display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    padding: '3em'
    }}>
      {/* <Joyride
        continuous
        callback={updateTour}
        // run={runTourDashboard}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        showProgress
        styles={tourStyles}
      /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "x-large",
          fontWeight: "bold",
        }}>
        <div
          style={{
            textAlign: "center",
            color: "#0A0F0A",
            fontSize: 30,
            fontFamily: "Inter",
            fontWeight: "700",
            wordWrap: "break-word",
          }}>
          Explore Adeptia’s Latest AI Innovations
        </div>
      </div>

      <div className='dashParentContainer'>
        <div
          id='dash-1'
          style={{
            width: 430,
            height: 422,
            paddingLeft: 20,
            margin:'30px',
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            background: "white",
            border: "4px #EDEDED solid",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
            display: "inline-flex",
          }}>
          <img src="images/Knowledge Base Icon.svg" style={{ width: 100, height: 100, background: "#D9D9D9" }} />
          <div
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 15,
              display: "flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}>
              <div
                style={{
                  flex: "1 1 0",
                  textAlign: "center",
                  color: "#0A0F0A",
                  fontSize: 25,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}>
                Generative AI <br/>Knowledge Base
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}>
              <div
                style={{
                  flex: "1 1 0",
                  textAlign: "center",
                  color: "#0A0F0A",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}>
                A secure, user-friendly implementation of Generative AI with
                Retrieval Augmented Generation to incorporate enterprise data
                with Large Language Models.
              </div>
            </div>
            <div
              style={{
                paddingLeft: 19,
                paddingRight: 19,
                paddingTop: 12,
                paddingBottom: 12,
                background: "#88AC2F",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                cursor:'pointer',
                display: "inline-flex",
              }}
              
              onClick={() => {
                history.push("/knowledgebase");
              }}
              
              >
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: 12.5,
                  fontFamily: "Inter",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  wordWrap: "break-word",
                }}>
                explore
              </div>
            </div>
          </div>
        </div>



        <div
         id='dash-2'
          style={{
            width: 430,
            height: 422,
            paddingLeft: 20,
            margin:'30px',
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            background: "white",
            border: "4px #EDEDED solid",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
            display: "inline-flex",
          }}>
          <img src="images/Data Extraction Icon.svg" style={{ width: 100, height: 100, background: "#D9D9D9" }} />
          <div
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 15,
              display: "flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}>
              <div
                style={{
                  flex: "1 1 0",
                  textAlign: "center",
                  color: "#0A0F0A",
                  fontSize: 25,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}>
                  Intelligent Document Processing
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}>
              <div
                style={{
                  flex: "1 1 0",
                  textAlign: "center",
                  color: "#0A0F0A",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}>
                 Extract information from your business documents. Simply upload your unstructured data (PDF or image) and convert into structured data (CSV or JSON).
              </div>
            </div>
            <div
              style={{
                paddingLeft: 19,
                paddingRight: 19,
                paddingTop: 12,
                paddingBottom: 12,
                background: "#88AC2F",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                display: "inline-flex",
                cursor:'pointer',
              }}
              
              onClick={() => {
                history.push("/fileprocessor");
              }}


              >
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: 12.5,
                  fontFamily: "Inter",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  wordWrap: "break-word",
                }}>
                explore
              </div>
            </div>
          </div>
        </div>



       
      </div>

      {/* <div className='dashParentContainer'>
        <Paper withBorder radius='md' className='dashcomment' id='dash-1'>
          <Group>
            <TipsAndUpdatesIcon style={iconStyle} />

            <div>
              <Text size='xl'>AI Knowledge Base</Text>
            </div>
          </Group>

          <TypographyStylesProvider className={classes.body}>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html:
                  "Retrieval Augmented Generation (RAG) solution to combine your enterprise business data with AI",
              }}
            />
            <Button
              onClick={() => {
                history.push("/knowledgebase");
              }}
              className={classes.exploreBtn}
              size='xs'>
              Explore
            </Button>
          </TypographyStylesProvider>
        </Paper>

        <Paper withBorder radius='md' className='dashcomment' id='dash-2'>
          <Group>
            <AccountTreeIcon style={iconStyle} />
            <div>
              <Text size='xl'>Extract Data</Text>
            </div>
          </Group>

          <TypographyStylesProvider className={classes.body}>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: "Extract structured data from unstructured sources",
              }}
            />
            <Button
              onClick={() => {
                history.push("/fileprocessor");
              }}
              className={classes.exploreBtn}
              size='xs'>
              Explore
            </Button>
          </TypographyStylesProvider>
        </Paper>
      </div> */}

      {/* <div className='dashParentContainer' style={{ marginBottom: "120px" }}>
        <Paper withBorder radius='md' className='dashcomment' id='dash-3'>
          <Group>
            <GavelIcon style={iconStyle} />
            <div>
              <Text size='xl'>AI Business Rules</Text>
            </div>
          </Group>

          <TypographyStylesProvider className={classes.body}>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html:
                  "Utilize AI to create rules that help businesses make decisions on input data",
              }}
            />
            <img src='images/comingsoon.png' style={{ width: "150px" }} />
           
          </TypographyStylesProvider>
        </Paper>

        <Paper withBorder radius='md' className='dashcomment' id='dash-4'>
          <Group>
            <RouteIcon style={iconStyle} />
            <div>
              <Text size='xl'>AI Data Mapper</Text>
            </div>
          </Group>

          <TypographyStylesProvider className={classes.body}>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: "Transform data from one format to another using AI",
              }}
            />
            <Button
              onClick={() => {
                posthog.capture("AI watch demo");
                window.open("https://vimeo.com/635535693");
              }}
              className={classes.exploreBtn}
              size='xs'>
              Watch Demo
            </Button>
          </TypographyStylesProvider>
        </Paper>
      </div> */}
      <Footer />
    </div>
  );
}

export default Dashboard;

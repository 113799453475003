import React, { useState, useRef } from 'react';
import './Drag.css'; // Import CSS for styling
import AttachFileIcon from '@mui/icons-material/AttachFile';
import posthog from 'posthog-js';

const DragAndDropContainer = ({method,file,setFile}) => {
    const [isDragging, setIsDragging] = useState(false);
    // const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
  
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragEnter = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      // setIsDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        setFile(files[0]);
        method(files[0]);
        setIsDragging(true);
      }
    };
  
    const handleFileSelectClick = () => {
      if (file) {
        // If a file is already selected, clear the selection
        setFile(null);
        method(null);
        setIsDragging(false);
      } else {
        // Otherwise, open file input for selection
        fileInputRef.current.click();
      }
    };
  
    const handleFileInputChange = (e) => {
      
      setIsDragging(true);
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        posthog.capture("File selected for extraction");
        method(selectedFile);
        setFile(selectedFile);
      }
    };
  
    return (
      <div
        className={`drag-drop-container ${(file)? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleFileSelectClick}
      >
        {file ? (
          <div>
            <span style={{
              fontSize: 'larger',
              color: 'blue',
              fontWeight: '700'
            }}>{file.name}</span>
            <br/>Click to replace.
          </div>
        ) : (
          <div>
           <AttachFileIcon /> Click or Drag & Drop File Here
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              accept=".pdf,.csv,.txt,.jpg,.jpeg,.png,.xml"
            />
          </div>
        )}
      </div>
    );
  };
  
  export default DragAndDropContainer;
import { Flex } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { IconButton as RIconButton } from "rsuite";
import { IconButton } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

// import './App.css';
function ReactPdf(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);
  const [defaultWidth, setDefaultWidth] = useState(380);
  const [windowHeight, setWindowHeight] = useState(0);

  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  useEffect(() => {
    if (window.innerHeight - 500 > 400) {
      setDefaultWidth(window.innerHeight - 520);
    } else {
      setDefaultWidth(370);
    }
  }, []);

  return (
    <div className='reactpdf'>
      <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
        <div
          style={{
            overflowY: "auto",
            overflowX: "auto",
            height: window.innerHeight - 370,
            width: window.innerWidth / 2 - 70,
          }}>
          <Page
            pageNumber={pageNumber}
            width={defaultWidth}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </div>
      </Document>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
        <IconButton
          onClick={() => {
            if (defaultWidth > 380) {
              setDefaultWidth(defaultWidth - 50);
            }
          }}>
          {" "}
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={() => setDefaultWidth(defaultWidth + 50)}>
          <ZoomInIcon />
        </IconButton>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
        {pageNumber > 1 && (
          <RIconButton
            icon={<ArrowLeftLineIcon />}
            onClick={changePageBack}
            style={{
              border: "1px solid green",
              color: "green",
              background: "#fff",
            }}
          />
        )}
        <p style={{ margin: "auto 15px auto 15px", fontWeight: "bold" }}>
          {pageNumber} of {numPages}
        </p>
        {pageNumber < numPages && (
          <RIconButton
            icon={<ArrowRightLineIcon />}
            onClick={changePageNext}
            style={{
              border: "1px solid green",
              color: "green",
              background: "#fff",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ReactPdf;

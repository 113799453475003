import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import posthog from 'posthog-js';


const SessionExpired = () => {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  posthog.capture("Session Expired");
  return (

    <div className="error-container">

      <div className='errorTextContainer'>
        <h1>Session timed out</h1>
        <p>Please try login again or <a style={{color:'#88AC2F',cursor:'pointer'}} onClick={() => {
           window.open('https://www.adeptia.com/about/contact-us');
        }}>contact</a> us if the problem persists</p>
        <div>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor:"#88AC2F"
          }}
          onClick={() => loginWithRedirect()}
          sx={{ mt: 3 }}
        >
          Log In
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor:"#88AC2F",
            marginLeft:20
          }}
          onClick={() => history.push('/home')}
          sx={{ mt: 3 }}
        >
          Return to Home
        </Button>
        </div>
    
      </div>
      <div className='errorImageContainer'>
      <img src='images/504 Image.png'  height="120%" width="100%"></img>
      </div>


    {/* <img src='images/session-expired.png' height="90%" width="30%"></img>
  <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor:"#88AC2F"
          }}
          onClick={() => loginWithRedirect()}
          sx={{ mt: 3 }}
        >
          Log In
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor:"#88AC2F"
          }}
          onClick={() => history.push('/home')}
          sx={{ mt: 3 }}
        >
          Return to Home
        </Button> */}
  
</div>
   
  );
};

export default SessionExpired;
import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { UserMenu } from "./user-menu";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Progress_bar from "./ProgressBar";
import { URL } from "../UrlConstants";
import { useSessionManager } from "../SessionManagerProvider";
import { fetchUser } from "./Helper";
import { useDisclosure } from "@mantine/hooks";
import posthog from "posthog-js";
import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  ActionIcon,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
  IconSun,
  IconMoonStars,
} from "@tabler/icons";

import { isMobile } from "react-device-detect";

import { IconFileFilled } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: IconCoin,
    title: "Cost Effective",
    description: "We belived in manging data at effective price",
  },
  {
    icon: IconBook,
    title: "Usage",
    description: "Easy to Use",
  },
  {
    icon: IconFingerprint,
    title: "Security",
    description: "We did not believe in sharing is caring",
  },
  {
    icon: IconFileFilled,
    title: "Unstructured",
    description: "Get your all data handy and ready to convertible",
  },
];

const NavbarWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes, theme } = useStyles();
  const [drawerOpened, { toggle: toggleDrawer }] = useDisclosure(false);
  const { usageValue, setUsageValue } = useSessionManager();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [userData, setUserData] = useState({
    maxUsage: 0,
    currentUsage: 0,
    firstName: "",
  });
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const fetchDataAsync = async () => {
        const accessToken = await getAccessTokenSilently();
        const value = await fetchUser(accessToken);
        setUsageValue(value);
      };
      fetchDataAsync();
    }
  }, [isAuthenticated]);

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align='flex-start'>
        <ThemeIcon size={34} variant='default' radius='md'>
          <item.icon size={22} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size='sm' weight={500}>
            {item.title}
          </Text>
          <Text size='xs' color='dimmed'>
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <nav className='navbar'>
      <div className='navbar-logo' style={{width: isMobile?"50%":""}}>
        <img
          src={isMobile ? 'images/Adeptia Labs Logo Light.png':'images/Adeptia Triangle Grey.svg'}
          style={{
            cursor: "pointer",
            width:isMobile?"100%":"60%"
          }}
          onClick={() => {
            history.push("/home");
          }}></img>
       
      </div>

      <div className={`navbar-links ${isOpen ? "active" : ""}`}>
       
          <div style={{ 
           
           
            color: 'white',
            cursor:'pointer',
            
            }}
            onClick={() => {
              history.push("/home");
            }}
            >
              <img src="images/Adeptia Labs Logo Light.png" height="35px" />
        
         
          </div>
      </div>
      <div className='navbar-user'>
        {!isAuthenticated && (
          <Button onClick={() => loginWithRedirect({})} variant='default' style={{ borderRadius: 0}}>
            LOGIN
          </Button>
        )}

        {isAuthenticated && <UserMenu />}
        {/* <Burger style={{background:'white'}} opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} /> */}
      </div>
    </nav>
  );
};

export default NavbarWrapper;

import React, { useState, useEffect } from "react";
import "./Base.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  MessageSeparator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { Modal, ButtonToolbar, Button, Placeholder } from "rsuite";
import { useParentContext } from "./context/ParentContext";
import posthog from "posthog-js";
import { URL } from "../../UrlConstants";
import { useAuth0 } from "@auth0/auth0-react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Hourglass } from "react-loader-spinner";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import { ColorRing } from  'react-loader-spinner'

const ChatBot = ({ rec, activeDocument }) => {
  const { testDialog, setTestDialog, fileDrawer, setFileDrawer } =
    useParentContext();
  const handleClose = () => setTestDialog(false);
  const [typeValue, setTypeValue] = useState("");
  const { tagSelected, setTagSelected } = useParentContext();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [lastMessage, setLastMessage] = useState("");
  const [userLastMessage, setUserLastMessage] = useState("");

  const userValue = isAuthenticated ? user.name : "User";

  const [messages, setMessages] = useState([
    {
      message:
        "<span> Hello! <b>" +
        userValue +
        "</b>, I am an Adeptia AI document assistant here to help you with any questions you may have about your documents.</span>",
      custom: true,
      continue: false,
      sentTime: "just now",
      sender: "adeptia",
    },
  ]);

  const initialMessage =  {
    message:
      "<span> Hello! <b>" +
      userValue +
      "</b>, I am an Adeptia AI document assistant here to help you with any questions you may have about your documents.</span>",
    custom: true,
    continue: false,
    sentTime: "just now",
    sender: "adeptia",
  };

  

  useEffect(() => {
    
    if (activeDocument !== "My") {
      
      let mess = [
        initialMessage,
        {
          message:
            "You are now interacting with Sample document - " + rec.sourceName,
          type: "spacer",
        },
      ];

      if (rec.questions !== null && rec.questions !== "") {
        mess = [...mess, JSON.parse(rec.questions)];
      }
      setMessages(mess);
      setIsTyping(false);
   
    } else {
      if (
        rec.status.toLocaleLowerCase() != "in-progress" &&
        rec.status.toLocaleLowerCase() != "failed"
      ) {

        let question = {"custom": true, "sender": "adeptia", "message": "<b>Possible questions you may ask</b> :", "continue": false, "questions": ["What is this document?", "What were the key findings from the document?"], "questionsFlag": true};


        let spacerMsg =
          "You are now interacting with your " + rec.sourceName + " document";
        let mess = [
          initialMessage,
          {
            message: spacerMsg,
            type: "spacer",
          },
          question
        ];
        setLastMessage("");
        setUserLastMessage("");
        setIsTyping(false);
        setMessages(mess);
        // initialSummaryMessage(mess);
      }
    }


    setLastMessage("");
    setUserLastMessage("");
  }, [rec]);

  const [isTyping, setIsTyping] = useState(false);

  const askQuestion = (e) => {
    setTypeValue(e.target.innerText);

  };

  // const initialSummaryMessage = (mess) => {
  //   handleSend("Summarize the context of this document",mess);
  // }

  const handleSend = async (message,list) => {
    let isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

    if (isHtml(message)) {
      const newMessage = {
        message: "Please use only text format to interact",
        custom: true,
        continue: false,
        sentTime: new Date().toDateString(),
        sender: "adeptia",
      };

      
      const newMessages = [...messages, newMessage];
      setMessages(newMessages);
      setTypeValue("");
      return;
    }
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    let newMessages = [];
    if(list !== undefined && list !== null && list !== message){
       newMessages = [...list, newMessage];
    }
    else{
       newMessages = [...messages, newMessage];
       setMessages(newMessages);
    }
   

    setIsTyping(true);
    setTypeValue("");
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    
    posthog.capture("Interact with AI using user as context");

    const senderMessage = chatMessages[chatMessages.length - 1];
    let apiRequestBody = {
      llm_query: senderMessage.message,
    };

    if (userLastMessage != "" || lastMessage != "") {
      apiRequestBody = {
        ...apiRequestBody,
        chat_history: {
          previous_query: userLastMessage,
          previous_response: lastMessage,
        },
      };
    }
    let intreacturl = URL.KNOWLEDGEBASEBYFILE + rec.id;
    let headers = {
      "Content-Type": "application/json",
    };
    if (activeDocument !== "My") {
      intreacturl = URL.SAMPLEINTERACTION + rec.id;
    } else {
      let accessToken = await getAccessTokenSilently();
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      };
    }

    const response = await fetch(`${intreacturl}`, {
      method: "POST",
      timeout: "30000",
      headers: headers,
      body: JSON.stringify(apiRequestBody),
    });

    let responseData = await response;
    if (responseData.status < 400) {
      responseData = await response.json();
      let newMessage = {
        message: responseData.llm_response,
        sentTime: new Date().toString(),
        sender: "adeptia",
      };

     
     const newMessages = [...chatMessages, newMessage];
     

      setMessages(newMessages);
      setIsTyping(false);
      setLastMessage(responseData.llm_response);
      setUserLastMessage(senderMessage.message);
    } else {
      let newMessage = {
        message:
          '<p style="color:red;">Apologies for the inconvenience. It appears there been an issue. Please try again. </p>',
        custom: true,
        sentTime: new Date(),
        sender: "adeptia",
      };

      const newMessages = [...chatMessages, newMessage];
      setMessages(newMessages);
      setIsTyping(false);
    }
  }

  return (
    <div style={{ height: "100%", background: "#fff" }}>
      {rec.status.toLocaleLowerCase() === "in-progress" && (
        <div className='chat-overlay'>
          <div className='overlayContainer'>
            <div className='overlayMsg'>
            <ColorRing
              visible
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
              Please wait,your document <b>{rec.sourceName}</b> is still being
              processed by the Adeptia AI system.
            </div>
          </div>
        </div>
      )}

      {rec.status.toLocaleLowerCase() === "failed" && (
        <div className='chat-overlay'>
          <div className='overlayContainer'>
            <div className='overlayMsg'>
              <SmsFailedIcon
                style={{
                  height: "80px",
                  width: "80px",
                  color: "red",
                }}
              />
              Your uploaded document <b> {rec.sourceName} </b> failed to
              process. Please try uploading again to start the Interact.
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          height: "40px",
          width: "calc(100% - 100px)",
        }}>
        <Tooltip title='Clear Chat'>
          <IconButton
            onClick={() => {
              setMessages([]);
            }}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      </div>

      <ChatContainer
        style={{
          padding: "20px",
          paddingLeft: "100px",
          paddingRight: "100px",
          height: "calc(100% - 40px)",
        }}>
        <MessageList
          scrollBehavior='auto'
          typingIndicator={
            isTyping ? (
              <TypingIndicator content='Adeptia is processing your request' />
            ) : null
          }>
          {messages.map((message, i) => {
            if (message.type !== "spacer") {
              return (
                <Message
                  //   onClick={() => {
                  //     setTypeValue("Test");
                  //   }}
                  key={i}
                  model={message}
                  avatarSpacer={message.continue}
                  avatarPosition='tl'
                  style={{ marginBottom: "0.5em" }}>
                  {message.sender !== "user" && !message.custom && (
                    <Message.Footer>
                      <div>
                        <Tooltip title='Copy'>
                          <IconButton
                            meta={message}
                            onClick={(e) => {
                              navigator.clipboard.writeText(message.message);
                            }}>
                            <ContentPasteIcon style={{ fontSize: "1.2rem" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Good Response'>
                          <IconButton
                            onClick={(e) => {
                              e.target.style.color = "#2828e3";
                              posthog.capture("Feedback", {
                                target: "Knowledge Base",
                                comment: "Good Response",
                              });
                            }}>
                            <ThumbUpOffAltIcon style={{ fontSize: "1.2rem" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Bad Response'>
                          <IconButton
                            onClick={(e) => {
                              e.target.style.color = "#2828e3";

                              posthog.capture("Feedback", {
                                target: "Knowledge Base",
                                comment: "Bad Response",
                              });
                            }}>
                            <ThumbDownOffAltIcon
                              style={{ fontSize: "1.2rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Message.Footer>
                  )}

                  {message.questionsFlag && (
                    <Message.Footer>
                      {" "}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {message.questions.map((item, i) => {
                          return (
                            <div
                              className='possibleQuestion'
                              onClick={askQuestion}>
                              {item}
                            </div>
                          );
                        })}
                      </div>
                    </Message.Footer>
                  )}

                  {!message.continue && message.sender == "adeptia" && (
                    <Avatar src='images/ico.png' name={"adeptia"} />
                  )}
                </Message>
              );
            } else {
              return (
                <MessageSeparator
                  content='You are now Interacting with'
                  as='h2'
                  key={i}>
                  <Avatar
                    src='images/Adeptia Triangle Grey.svg'
                    name='Adeptia'
                  />
                  {message.message}
                </MessageSeparator>
              );
            }
          })}
        </MessageList>
        <MessageInput
          attachButton={false}
          placeholder='Type your question here...'
          value={typeValue}
          autoFocus
          disabled={rec.status.toLocaleLowerCase() !== "completed"}
          style={{ backgroundColor: "#fff" }}
          onSend={handleSend}
          sendDisabled={typeValue === ""}
          onChange={(e) => {
            setTypeValue(e);
          }}
        />
      </ChatContainer>
    </div>
  );
};

export default ChatBot;

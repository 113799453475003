import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import posthog from "posthog-js";

const SessionManagerContext = createContext();

export const useSessionManager = () => {
  return useContext(SessionManagerContext);
};

export const SessionManagerProvider = ({ children }) => {
  const { isAuthenticated, logout } = useAuth0();
  let logoutTimeout;

  const [usageValue, setUsageValue] = useState(0);

  const [disclaimerDrawer, setDisclaimerDrawer] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [runTourKnowledgbase, setRunTourKnowledgbase] = useState(false);
  const [runTourDashboard, setRunTourDashboard] = useState(false);
  const[mobileError,setIsMobileError]= useState(false);

  const tourStyles = {
    options: {
      primaryColor: "rgb(136, 172, 47)", // Change this to your desired color
    },
  };

  useEffect(() => {
    const handleUserActivity = () => {
      clearTimeout(logoutTimeout);

      logoutTimeout = setTimeout(() => {
        posthog.capture("Session Expired");
        logout({
          logoutParams: {
            returnTo: window.location.origin + "/#session-expired",
          },
        });
      }, 1800000); // 30 minutes in milliseconds
    };

    if (isAuthenticated) {
      handleUserActivity();

      // Listen for user activity events
      document.addEventListener("mousemove", handleUserActivity);
      document.addEventListener("keydown", handleUserActivity);
    }

    return () => {
      // Cleanup event listeners
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      clearTimeout(logoutTimeout);
    };
  }, [isAuthenticated, logout]);

  return (
    <SessionManagerContext.Provider
      value={{
        usageValue,
        setUsageValue,
        disclaimerDrawer,
        setDisclaimerDrawer,
        loggedInUser,
        setLoggedInUser,
        tourStyles,
        runTourKnowledgbase,
        setRunTourKnowledgbase,
        runTourDashboard,
        setRunTourDashboard,
        mobileError,setIsMobileError
      }}>
      {children}
    </SessionManagerContext.Provider>
  );
};

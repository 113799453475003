import { ParentContextProvider } from './context/ParentContext';
import KnowWrapper from './Wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';

function ContextWrapper() {
  return (
  <ParentContextProvider>
    <KnowWrapper />
    <ToastContainer />
  </ParentContextProvider>
  );
}

export default ContextWrapper;

import React from "react";
import loading from "../assets/loading.svg";

const centerstyle = { 
  display: "flex",              /* Enable Flexbox */
  justifyContent: "center",    /* Center items horizontally */
  alignItems: "center",
  height: '100vh'        
 }

const Loading = () => (
  <div style={centerstyle}>
  <div className="spinner">
    <img src={loading} alt="Loading" />
    <img src="logo.png" alt="Loading" />
  </div>
  </div>
);

export default Loading;
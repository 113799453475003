import { Modal, ButtonToolbar, Placeholder } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";

const LoginModal = (props) =>{

    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();
    return( <Modal open={props.loginModalactive} onClose={() => props.setLoginModalActive(false)} size='sm'>
    <Modal.Header></Modal.Header>

    <Modal.Body>
      {/* <div style={{textAlign: 'center',fontWeight: 600,fontSize: 'medium'}}>You can quickly extract information from your business documents. Simply upload your unstructured data (PDF or image) and convert into structured data (CSV or JSON).</div> */}

      <div
        style={{
          background: "white",
          flexDirection: "center",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
         
        }}>
        <div
          style={{
            alignSelf: "stretch",
            height: 144,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
            display: "flex",
          }}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex",
            }} 
           
            
            >
            <div
              style={{
                textAlign: "center",
                color: "#0A0F0A",
                fontSize: 30,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
              }}>
             Join Adeptia Labs!
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex",
            }}>
            <div style={{ flex: "1 1 0", textAlign: "center" }}>
              <span
                style={{
                  color: "black",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}>
                Login {" "}
              </span>
            
          
              <span
                style={{
                  color: "black",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}>
                or create an account to upload your own documents.
                
              </span>
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}>
              <div
                style={{
                  paddingLeft: 19,
                  paddingRight: 19,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#88AC2F",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  display: "inline-flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  loginWithRedirect();
                }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: 12.5,
                    fontFamily: "Inter",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    wordWrap: "break-word",
                  }}>
                  LOGIN
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)}

export default LoginModal;
import { createStyles, Container, Group, ActionIcon } from '@mantine/core';
import { IconBrandYoutube, IconBrandInstagram ,IconBrandLinkedin,IconBrandTwitter} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  footer: {
    position: 'fixed',
    background:'#fff',
    bottom: 0,
    width:'100%',
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function Footer() {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <p>Adeptia Labs</p>
        <Group spacing={0} className={classes.links} position="right" noWrap>
          
          <ActionIcon size="lg">
            <IconBrandYoutube size={18} stroke={1.5} onClick={()=>{
              window.open("https://www.youtube.com/@Adeptia312")
            }}/>
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandLinkedin size={18} stroke={1.5} onClick={() => {
              window.open("https://in.linkedin.com/company/adeptia")
            }} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandTwitter size={18} stroke={1.5} onClick={() => {
              window.open(" https://twitter.com/adeptia")
            }} />
          </ActionIcon>


          
         
        </Group>
      </Container>
    </div>
  );
}
import axios from 'axios';
import {  toast } from 'react-toastify';
import { URL } from '../UrlConstants';


export const dateParser = (date,time) => {
    
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    const [month, day, year] = formattedDate.split(' ');
    const capitalizedMonth = month.toUpperCase();
    let finalDate =  `${day} ${capitalizedMonth} ${year}`;

    if(time === true){
        
        const hh = new Date(date).getHours();
        const mm =  new Date(date).getMinutes();
        const ss =  new Date(date).getSeconds();
        finalDate = finalDate+' '+hh+':'+mm+':'+ss;
    }

    return finalDate;


}


export const UpdateUser = async(userValue,accessToken) => {
      const update = async () =>{
         const response =  await axios.post(URL.USERUPDATE, userValue, {
            headers: {
              "Authorization":"Bearer "+accessToken
            }
          }).then((response) => {
            return response.data;

                      }).catch(error => {
                        
                        toast.error("Error while updating user", {
                          position: toast.POSITION.TOP_CENTER,
                          autoClose: 7000, // Close the toast after 3 seconds
                        })
                      });
            return response;
        
        
    }
    const value = await update();
    return value;
}

export const fetchUser = (token) => {
  const fetchuser = async () => {
    try {
      
      const response = await fetch(`${URL.GETUSER}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + token
        }
      });
      
      if (response.status < 400) {
        
        const jsonValue = await response.json();

        return jsonValue;

      } else {
        return null;
        
      }
    } catch (error) {
      return null; // Return an appropriate default value or handle the error accordingly
    }
  };

  const fetchValue = fetchuser();
  return fetchValue;
}

export const calculateProgress = (count,current) => {
  return count !== 0 ? ((current / count) * 100).toFixed(0) : 'N/A'
}



import React from 'react'
import { Tooltip } from '@mui/material'
 
const Progress_bar = ({bgcolor,progress,height}) => {
    
    const Parentdiv = {
       
        width: '80%',
        backgroundColor: '#dddcdc',
        borderRadius: 40,
        height: '70%',
        marginBottom:'1em'
        // margin: 'auto',
        // marginRight:'1em'
      }
     
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
       borderRadius:40,
        textAlign: 'center',
        cursor:'pointer'
      }
     
      const progresstext = {
        paddingLeft: '3vw',
        color: '#4d4747',
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
        whiteSpace: 'nowrap'
        
      }
       
    return (
 
      <div style={Parentdiv}>
      <div style={Childdiv}>
        <Tooltip title={
          <span key={Date.now()}>
            {["Max Documents Allowed : 10", <br key="br" />, "Current : " + `${progress / 10}`]}
          </span>
        }>
          <span style={progresstext}>{`Usage ${progress}%`}</span>
        </Tooltip>
      </div>
    </div>

    )
}
 
export default Progress_bar;
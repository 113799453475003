import './App.css';
import { Navbar } from './components/navbar';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
// import { Router, Route, Switch } from "react-router-dom";
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import FileProcess from './components/unstructure/FileProcess';
import Dashboard  from './components/dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import {createBrowserHistory} from "history";
import { HeroText } from './components/hero';
import Loading from './components/Loading';
import { Profile } from './components/profile';
// import KnwwoledgeParentWrapper from './components/KnowledgeWrapper';
import NavbarWrapper from './components/NavbarWrapper';

import 'rsuite/dist/rsuite.min.css';
import ErrorPage from './components/Error';
import { useHistory } from 'react-router-dom';
import SessionExpired from './components/SessionExpired';
import { UpdateUser } from './components/Helper';
import { Footer } from './components/Footer';
import KnowWrapper from './components/kno/Wrapper';
import ContextWrapper from './components/kno/ContextWrapper';
import Disclaimer from './components/Disclaimer';
import { useSessionManager } from './SessionManagerProvider';
import TermsCondition from './components/Terms&Condition';
import posthog from 'posthog-js';
import EvaluateWrapper from './components/evaluate/Wrapper';
import ContextEvalauteWrapper from './components/evaluate/ContextWrapper';
import "./components/kno/Base.css"
import MobileDevice from './MobileDevice';

function App() {
 
  const { user,isLoading, error , isAuthenticated, getAccessTokenSilently } = useAuth0();
  // const [colorScheme, setColorScheme] = useState('light');
  const history = useHistory();
  const {loggedInUser,setLoggedInUser,disclaimerDrawer,setDisclaimerDrawer,setIsMobileError} = useSessionManager();
  


  const getToken = async () => {
    const accessToken = await getAccessTokenSilently();
    const loguser =  await UpdateUser(user,accessToken);
    setLoggedInUser(loguser);
    setDisclaimerDrawer(!loguser.disclaimer);
  }

  
    if(window.location.pathname !== '/'){
      window.location.href = window.location.origin+'/#error'
      
    }


    if (error) {
      
      window.location.hash  = 'error';

    }
    if(isAuthenticated) {
      
      posthog.identify(user.email);
      posthog.capture("User-Logged In");
      // getToken(user);
      if( window.location.hash === ''){
        window.location.hash  = 'home'
      }
      
    }

    useEffect(() => {
      if(isAuthenticated) {
        getToken();
      }
    },[isAuthenticated]);

   

    
  if (isLoading) {
    return <Loading />;
  }

  

 

  return (

    <Router hashType="noslash">
    <NavbarWrapper/>
    <MobileDevice/>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/home" render={() => (
           <Dashboard />
          )} />
          <Route path="/profile" render={() => (
            isAuthenticated ? <Profile /> : <ErrorPage message={"404, PAGE NOT FOUND"} />
          )} />
          <Route path="/knowledgebase" render={() => (
           <ContextWrapper />
          )}/>
          <Route path="/fileprocessor" render={() => (
            <FileProcess /> 
          )}/>

          {/* <Route path="/faq" render={() => (
            isAuthenticated ? <Faq /> : <ErrorPage message={"404, PAGE NOT FOUND"} />
          )}/> */}
          
          <Route path="/termsCondition" render={() => (
            isAuthenticated ? <TermsCondition /> : <ErrorPage message={"404, PAGE NOT FOUND"} />
          )} />
          
          <Route path="/session-expired" render={() => (
            isAuthenticated ? <ErrorPage message={"404, PAGE NOT FOUND"} /> : <SessionExpired /> 
          )} />
          
          
          <Route path="/error" render={() => (
            error ? <ErrorPage message={error.message} /> : <ErrorPage message={"404, PAGE NOT FOUND"} /> 
          )} />

          {/* <Route path="/evaluate" exact component={ContextEvalauteWrapper} /> */}



          <Route path='*' component={() => { return ( <ErrorPage message={"404 , PAGE NOT FOUND"} />) }}/>
        </Switch>
        {/* <Footer/> */}
        <Disclaimer />
    </Router>
  );
}

export default App;

import { Modal, ButtonToolbar, Button, Placeholder } from "rsuite";
import React, { useState } from "react";
import "./Feedback.css";
import { TextField } from "@mui/material";
import posthog from "posthog-js";
import Rating from "@mui/material/Rating";

const FeedBackModal = ({ open, setOpen, target }) => {
 


  const [emo, setEmo] = useState(null);
  const [value, setValue] = useState("");
  const handleClose = () => {
    setEmo(null);
    setValue("");
    setOpen(false);
  }

  return (
    <>
      <Modal size='sm' open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title
            style={{ display: "flex", fontWeight: 600, fontSize: "25px" }}
            className='modalTitle'>
            Help us improve!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='feedBackContainer'>
            <div className='emojiText'>
              What did you think for this feature?
            </div>
            <div className='emojicontainer'>
              <Rating
                name='simple-controlled'
                size='large'
                value={emo}
                onChange={(event, newValue) => {
                  setEmo(newValue);
                }}
              />
            </div>

           
            <div className='emojiText'>Tell us more...</div>
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              placeholder='Provide your feedback'
              multiline
              rows={3}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}>
              <div
                style={{
                  paddingLeft: 19,
                  paddingRight: 19,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: "#88AC2F",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  display: "inline-flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  //Submit
                  posthog.capture("Feedback", {
                    target: target,
                    feedback: emo,
                    comment: value,
                  });

                  setOpen(false);
                }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: 12.5,
                    fontFamily: "Inter",
                    fontWeight: "800",
                    textTransform: "uppercase",
                    wordWrap: "break-word",
                  }}>
                  Submit
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FeedBackModal;

import React, { createContext, useContext, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const ParentContext = createContext();

export const useParentContext = () => {
  return useContext(ParentContext);
};

export const ParentContextProvider = ({ children }) => {
  

  // const { user ,isAuthenticated,getAccessTokenSilently} = useAuth0();
    const[create,setCreate] = useState(false);
    const[endpoint,setEndpoint] = useState(false);
    const[testDialog,setTestDialog] = useState(false);
    const[fileDrawer,setFileDrawer] = useState(false);
    const[refresh,setRefresh] = useState(false);
     const[query,setQuery] = useState('');

     const [tagSelected,setTagSelected] = useState('');

    const[knowledgeRecord,setKnowledgeRecord] = useState({});

    const[token,setToken] = useState("");

    // const fetchProtectedData = async () => {
    //   const accessToken = await getAccessTokenSilently();
    //   setToken(accessToken);
    // }
    // if(isAuthenticated){
     
    //   fetchProtectedData();
     
    // }

  return (

    <ParentContext.Provider value={{create,setCreate,endpoint,setEndpoint,testDialog,setTestDialog,refresh,setRefresh,query,setQuery,
      knowledgeRecord,setKnowledgeRecord,tagSelected,setTagSelected,fileDrawer,setFileDrawer
    }}>
      {children}
    </ParentContext.Provider>
  );
};
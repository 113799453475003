import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from 'history';
import { SessionManagerProvider } from "./SessionManagerProvider";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Make sure to import the CSS for react-toastify
import { getConfig } from "./config";
import { URL } from "./UrlConstants"
import { PostHogProvider} from 'posthog-js/react'
import MobileDevice from "./MobileDevice";
import { isMobile } from "react-device-detect";


const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  console.log(window.location.pathname);
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const getConfigFromServer = () => {
  return fetch(URL.getConfig)
    .then(response => response.json())
    .then(data => {
      return {
        data,
        providerConfig:{
          domain: data.domain,
          clientId: data.clientId,
          ...(data.audience ? { audience: data.audience } : null),
          
         
         
          onRedirectCallback,
        }
      };
    })
    .catch(error => {
      console.error(error);
      return {}; // return empty object or handle error as per your requirement
    });
};

getConfigFromServer().then(config => {

  
  const root = ReactDOM.createRoot(
    document.getElementById('root')
  );

  const options = {
    api_host:config.data.posthoghost,
  }

  // if(isMobile){
  //   root.render(
  //    <MobileDevice/>
  //   )
  // }
  // else{
    root.render(
      <PostHogProvider 
      apiKey={config.data.posthogkey}
      options={options}
    >
      <Auth0Provider {...config.providerConfig}  authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.providerConfig.audience,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        
      }}>
        <SessionManagerProvider>
          <App />
          <ToastContainer />
        </SessionManagerProvider>
      </Auth0Provider>
      </PostHogProvider>
    );
  // }


});
import React, {useState,useEffect} from "react"
import { Modal, ButtonToolbar, Button, RadioGroup, Radio, Placeholder } from 'rsuite';

import { styled } from '@mui/material/styles';
import ButtonMat from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControl from '@mui/material/FormControl';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { TextField, Container } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import './Common.css';
import Tooltip from "@mui/material/Tooltip";
import axios from 'axios';
import {  toast } from 'react-toastify';
import { URL,user } from '../../UrlConstants';
import { ColorRing } from  'react-loader-spinner'
import { useSessionManager } from "../../SessionManagerProvider";
import posthog from 'posthog-js';
import { fetchUser } from "../Helper";
import DragAndDropContainer from "../unstructure/DragDropContainer";

const styles = {
  radioGroupLabel: {
    padding: '8px 12px',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const CreateDialogNew = (props) => {


  const [errorCode, setErrorCode] = useState([]);

  const[loader,setLoader] = useState(false);

  const [file,setFile] = useState(null)

  const [requiredErrorCode,setrequiredErrorCode] = useState("");
  const {usageValue,setUsageValue} = useSessionManager();
 

  const [dialogValue, setDialogValue] = useState({
    name: "",
    description: "",
  });  
  const [toggleopen, settoggleOpen] = useState(false);

  const[resRec,setResRec] = useState({
    
  });

  const [record,setRecord] = useState({   
  description:'',
  fullName:'' ,
  tagName: ''})
  // const {create,setCreate,endpoint,setEndpoint,refresh,setRefresh,query,setQuery, knowledgeRecord,setKnowledgeRecord,fileDrawer,setFileDrawer,token} = useParentContext();

  const { user ,isAuthenticated,getAccessTokenSilently} = useAuth0();


  const handleClose = () => {
    setRecord({   
      description:'',
      fullName:'' ,
      tagName: ''});
    setLoader(false);
    setFile(null);
    
    props.setCreate(false);

  }

  const progressVal = async (e) => {
    const accessToken = await getAccessTokenSilently();
    const progress = await fetchUser(accessToken);
    setUsageValue(progress);
  }


  const handleSubmit = async (e) => {
    posthog.capture("Add Knowledge to the store");
    setLoader(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', record.description);
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await axios.post(URL.PUSHRECORD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization":"Bearer "+accessToken
        }
      }).then((response) => {
            if(response.status === 200){
              setLoader(false);
              // setRefresh(!refresh);
              handleClose();
              progressVal();
              setFile(null)
              props.setRefresh(!props.refresh);
              props.setNewUpload(true);
              toast.success(response.data, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 7000, // Close the toast after 3 seconds
                      });
            }
         
                  }).catch(error => {
                    
                    setLoader(false);

                    if(error.response.status === 429){
                      handleClose();
                      progressVal();
                      setFile(null);
                     return props.setdusage(true);
                    }

                    toast.error(error.response.data, {
                      position: toast.POSITION.TOP_CENTER,
                      autoClose: 7000, // Close the toast after 3 seconds
                    })
                  });
    
    } catch (error) {

    

      setLoader(false);
      console.error('Error uploading file', error);
    }
  };


  return (
    
      <Modal backdrop='static' keyboard={false} open={props.create} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title><b>Provide your document to interact with Adeptia AI</b></Modal.Title>
        </Modal.Header>

        <Modal.Body>
    

       {loader && <div className="loader-overlay">
            <ColorRing
              visible={loader}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
            </div>}
            <div>
            <DragAndDropContainer method={setFile} file={file} setFile={setFile} />
            {/* <div className="dialogSection"> 
                   <label className="dialogItemLabel">Upload File</label>
                   <ButtonMat component="label" variant="contained" startIcon={<CloudUploadIcon />} size="small" 
                   style={{marginRight: '10px'}}>

                    
                        Select file
                        <VisuallyHiddenInput type="file" accept="application/pdf, .csv, .txt ,.xml" onChange={(e) =>
                          {
                            if(e.target.files[0].size/1024**2 < 5){

                              
                              setRecord(
                                {
                                  ...record,
                                  fullName:e.target.files[0].name
                                }
                              )
                              setFile(e.target.files[0])
                            }
                            else{
                              e.target.value = '';
                              toast.error('Maximum file size supported is of 5 MB', {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000, // Close the toast after 3 seconds
                              });
                            }
                         
                       
                          }
                          }
                     />
                        </ButtonMat>

                        <div className="fileUploadText"><Tooltip title={record.fullName}>{record.fullName}</Tooltip></div>
                        
                </div> */}
                <div style={{display:'flex', justifyContent:'center',color:'#1565c0' , margin:"5px"}}>Supported file formats are PDF, CSV, TXT, JPG, PNG and XML</div>
                <div style={{display:'flex', justifyContent:'center',color:'#1565c0' , margin:"5px"}}>Maximum file size is 10MB</div>
               
                {/* <div className="dialogSection">  */}
                {/* <FormControl fullWidth style={{marginTop:'10px'}}>
                   <TextField id="outlined-basic" size='small' variant="outlined" 
                     multiline
                     
                     rows={4}
                     label="Description"
                     value={record.description} 
                     onChange={(event) => {
                      setRecord({
                        ...record,
                        description:event.target.value
                      })
                    }}
                     
                     />
                     </FormControl> */}
                {/* </div> */}
        
              

            </div>

        </Modal.Body>
        <Modal.Footer>
         
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button
          disabled={file === null}
          style={{
            background:'rgb(136, 172, 47)',
          }}
          onClick={handleSubmit} appearance="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>

  );
};

export default CreateDialogNew;
import React, { useState, useEffect, useRef } from "react";
import "./Base.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { toast } from "react-toastify";
import { Refresh } from "@mui/icons-material";
import BiotechIcon from "@mui/icons-material/Biotech";
import { Modal, ButtonToolbar, Placeholder } from "rsuite";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { URL, user } from "../../UrlConstants";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import posthog from "posthog-js";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Progress_bar from "../ProgressBar";
import CreateDialogNew from "./CreateDialog";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import { calculateProgress } from "../Helper";
import ChatBot from "./ChatBot";
import { ColorRing } from "react-loader-spinner";
import ScienceIcon from "@mui/icons-material/Science";
import FeedBackModal from "../FeedBackModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { useSessionManager } from "../../SessionManagerProvider";
import LoginModal from "../LoginModal";
import axios from "axios";


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "&.MuiAccordionSummary-root.Mui-expanded": {
    minHeight: 40,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px !important",
  },
}));

const KnowWrapper = () => {
  const chatRef = useRef();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [create, setCreate] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [typeValue, setTypeValue] = useState("");
  const [rec, setRec] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [currSelection, setCurrSelection] = useState({});
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [usageValue, setUsageValue] = useState(0);
  const [currInteract, setCurrInteract] = useState("all");
  const [initialLoader, setIntitalLoader] = useState(true);
  const [emptyData, setEmptyData] = useState(false);
  const [tryOut, setTryOut] = useState(false);
  const [activeDocument, setActiveDocument] = useState("");
  const [open, setOpen] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);
  const [openFeedback, setFeedback] = useState(false);
  const [delRec, setDelRec] = useState(null);
  const [dusage, setdusage] = useState(false);
  const history = useHistory();
  const [newUpload, setNewUpload] = useState(false);
  const [statusTimer, setStatusTimer] = useState(null);
  const [loginModalactive,setLoginModalActive] = useState(false);

  const {
    mobileError,setIsMobileError
  } = useSessionManager();


  const handleExpandChange = (panel) => (event, isExpanded) => {
    setActiveDocument(isExpanded ? panel : false);
  };

 

  const handleClose = () => setOpen(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const docList = function () {
    if (activeDocument === "My") {
      return (
        <ul
          className='menu-links'
          style={{
            maxHeight: "calc(100vh - 520px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}>
          {rec.map((item) => {
            return (
              <li className='nav-link' key={item.id}>
                {item.status.toLocaleLowerCase() === "completed" && (
                  <Tooltip title='Ready' placement='bottom'>
                    <VerifiedIcon style={{ color: "green" }} />
                  </Tooltip>
                )}

                {item.status.toLocaleLowerCase() === "failed" && (
                  <Tooltip title={item.storeResponse} placement='bottom'>
                    <NewReleasesRoundedIcon style={{ color: "red" }} />
                  </Tooltip>
                )}

                {item.status.toLocaleLowerCase() === "in-progress" && (
                  <Tooltip title='In-Progress' placement='bottom'>
                    <RunCircleIcon style={{ color: "#ff9b37" }} />{" "}
                  </Tooltip>
                )}

                <a
                  className={
                    activeDocument === "My" && currInteract.id === item.id
                      ? "liactive"
                      : ""
                  }
                  onClick={(e) => {
                    setCurrInteract(item);
                  }}>
                  <span className='text nav-text'>{item.sourceName}</span>
                </a>
                {item.createdBy === user.email && (
                  <Tooltip title={"Delete " + item.sourceName}>
                    <DeleteOutlineIcon
                      onClick={(e) => {
                        setCurrSelection(item);
                        setDialogOpen(true);
                      }}
                    />
                  </Tooltip>
                )}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul
          className='menu-links'
          style={{
            maxHeight: "150px",
            overflowY: "auto",
            overflowX: "hidden",
          }}>
          {sampleData.map((item) => {
            return (
              <li className='nav-link' key={item.id}>
                {item.status.toLocaleLowerCase() === "completed" && (
                  <Tooltip title='Ready' placement='bottom'>
                    <VerifiedIcon style={{ color: "green" }} />
                  </Tooltip>
                )}

                {item.status.toLocaleLowerCase() === "failed" && (
                  <Tooltip title={item.storeResponse} placement='bottom'>
                    <NewReleasesRoundedIcon style={{ color: "red" }} />
                  </Tooltip>
                )}

                {item.status.toLocaleLowerCase() === "in-progress" && (
                  <Tooltip title='In-Progress' placement='bottom'>
                    <RunCircleIcon style={{ color: "#ff9b37" }} />{" "}
                  </Tooltip>
                )}

                <a
                  className={
                    activeDocument != "My" && currInteract.id === item.id
                      ? "liactive"
                      : ""
                  }
                  onClick={(e) => {
                    setCurrInteract(item);
                  }}>
                  <span className='text nav-text'>{item.sourceName}</span>
                </a>

                <Tooltip title={"Download " + item.sourceName}>
                  <DownloadIcon
                    onClick={(e) => {
                      window.open("/SampleDocs/" + item.downloadUrl);
                    }}
                  />
                </Tooltip>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const statusUpdate = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await fetch(`${URL.STATUSUPDATE}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      let responseData = await response;
      if (responseData.status < 400) {
        setStatusCheck(false);
        setRefresh(!refresh);
      }
    } catch (Exception) {
      console.log(Exception);
    }
  };

  const fetchdata = async () => {
    // setLoading(true);
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await fetch(`${URL.KNOWLEDGEBASE}?&page=0&size=100`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      let responseData = await response;
      if (responseData.status < 400) {

        responseData = await response.json();
        let flag = false;

        responseData.knowledgebase.map((item) => {
          if (item.status === "In-Progress") {
            flag = true;
          }
        });
        clearTimeout(statusTimer);
        if (flag === true) {
          const timerId = setTimeout(function () {
            statusUpdate();
          }, 5000);
          setStatusTimer(timerId);
        } else {
          setStatusCheck(false);
        }

        setRec(responseData.knowledgebase);
        setSampleData(responseData.sharedKnowledgebase);
        if (initialLoader) {
          if (responseData.knowledgebase.length > 0) {
            setActiveDocument("My");
            setCurrInteract(responseData.knowledgebase[0]);
          } else {
            setActiveDocument("Sample");
            setCurrInteract(responseData.sharedKnowledgebase[0]);
          }
        }
        if (delRec !== null) {
          if (responseData.knowledgebase.length > 0) {
            setActiveDocument("My");
            setCurrInteract(responseData.knowledgebase[0]);
          } else {
            setActiveDocument("Sample");
            setCurrInteract(responseData.sharedKnowledgebase[0]);
          }
          setDelRec(null);
        }
        setIntitalLoader(false);

        const usage = responseData.knowledgebase.filter((item) => {
          if (item.createdBy != user.email) {
            return false;
          } else {
            return true;
          }
        });
        const usageVal = calculateProgress(responseData.count, usage.length);
        setUsageValue(usageVal);

        if (newUpload) {
          setActiveDocument("My");
          setCurrInteract(responseData.knowledgebase[0]);
          setNewUpload(false);
        } else if (
          currInteract.status.toLocaleLowerCase() === "in-progress" &&
          responseData.knowledgebase[0].id === currInteract.id &&
          responseData.knowledgebase[0].status.toLocaleLowerCase() ===
            "completed"
        ) {
          setCurrInteract(responseData.knowledgebase[0]);
        }

       
      } else if (responseData.status === 401) {
        logout({
          returnTo: window.location.origin + "/#session-expired", // Specify the logout URL here
        });
      }
    } catch (Exception) {
      // setLoading(false);
    }
  };

  const deleteRec = async () => {
    setLoader(true);
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${URL.DELETE}?&id=${currSelection.id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    let responseData = await response;
    if (responseData.status < 400) {
      setRefresh(!refresh);
      setLoader(false);
      setDialogOpen(false);
      if (currInteract === currSelection) {
        setDelRec(currSelection);
      }

      toast.success("Document removed successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000, // Close the toast after 3 seconds
      });
    } else {
      setLoader(false);
      toast.error("Error while removing, please try again", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000, // Close the toast after 3 seconds
      });
    }
  };

  const fetchSampleData = async (e) => {
    const response = await axios
      .get(URL.SAMPLEDOCLIST)
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          setSampleData(response.data);
          setActiveDocument("Sample");
          if(response.data.length > 0){
            setCurrInteract(response.data[0]);
            setIntitalLoader(false);
          }
          
         
          // setCurrentInt(response.data[0])
        }
      })
      .catch((error) => {
       
      });
  };

  useEffect(() => {
    if(isMobile){
      setIsMobileError(true);
    }
    else{
      if(isAuthenticated){
        fetchdata();
      }
      else{
        fetchSampleData();
      }

      
    }
  }, [refresh]);

  return (
    <div>
      <FeedBackModal
        open={openFeedback}
        setOpen={setFeedback}
        target='KnowledgeBase'
      />
      <div style={{ height: "80px" }}>
        <h1 className='text'>
          <span>Generative AI Knowledge Base </span>
        </h1>
        <div style={{display:"flex",marginTop:'10px'}}>
        <div
          className='overviewDivWrapper'
          style={{ marginLeft: "60px" }}
          onClick={() => history.push("/home")}>
          <ArrowBackIcon />
        </div>
        <div
          className='overviewDivWrapper'
          style={{ marginLeft: "10px" }}
          onClick={() => setOpen(true)}>
          <div>Feature Overview</div>
        </div>
        <div
          className='overviewDivWrapper'
          style={{ marginLeft: "10px" }}
          onClick={() => setFeedback(true)}>
          <div>Provide Feedback</div>
        </div>
        </div>
     
      </div>

      <Modal open={dusage} onClose={() => setdusage(false)} size='sm'>
        <Modal.Header></Modal.Header>

        <Modal.Body>
          {/* <div style={{textAlign: 'center',fontWeight: 600,fontSize: 'medium'}}>You can quickly extract information from your business documents. Simply upload your unstructured data (PDF or image) and convert into structured data (CSV or JSON).</div> */}

          <div
            style={{
              background: "white",
              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                height: 144,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
                display: "flex",
              }}>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "#0A0F0A",
                    fontSize: 30,
                    fontFamily: "Inter",
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}>
                  Quota Reached!
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div style={{ flex: "1 1 0", textAlign: "center" }}>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    Contact{" "}
                  </span>
                  <span
                    onClick={() => {
                      window.open("https://www.adeptia.com/about/contact-us");
                    }}
                    style={{
                      color: "#88AC2F",
                      fontSize: 16,
                      cursor: "pointer",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      textDecoration: "underline",
                      wordWrap: "break-word",
                    }}>
                    Adeptia sales
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    to remove limits or see more capabilities for generative AI.
                    <br />
                    <br />
                  </span>
                  <span
                    style={{
                      color: "#7D7D7D",
                      fontSize: 14,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    Up to 10 files at a time and 10 MB per file
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal open={open} onClose={handleClose} size='sm'>
        <Modal.Header>
          {/* <Modal.Title style={{justifyContent: 'center',display: 'flex',fontSize: '25px'}}>Generative AI Knowledge Base</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              padding: 10,
              background: "white",

              borderRadius: 20,
              overflow: "hidden",

              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
                display: "flex",
              }}>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div style={{ flex: "1 1 0", textAlign: "center" }}>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    Adeptia’s{" "}
                  </span>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "700",
                      wordWrap: "break-word",
                    }}>
                    Generative AI Knowledge Base{" "}
                  </span>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    is one of the innovative features being developed in Adeptia
                    Labs. With this Retrieval Augmented Generation (RAG) feature
                    organizations can leverage the power of Generative AI and
                    Large Language Models with their enterprise data in a
                    secure, user-friendly environment.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div style={{textAlign: 'center',fontWeight: 600,fontSize: 'medium'}}>It is one of the innovative features being developed in Adeptia Labs.  With this feature organizations can leverage the power of Generative AI and Large Language Models with their enterprise data in a secure, user-friendly environment.</div> */}
        </Modal.Body>
      </Modal>
      <div className='parentDiv'>
        <CreateDialogNew
          create={create}
          setCreate={setCreate}
          refresh={refresh}
          setRefresh={setRefresh}
          setdusage={setdusage}
          setNewUpload={setNewUpload}
        />
        <LoginModal loginModalactive={loginModalactive} setLoginModalActive={setLoginModalActive} />

        <Dialog
          open={dialogopen}
          onClose={handleDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            {"Are you sure you want to remove ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Removing the document will also remove it from your AI context,
              and you will not be able to interact with it anymore.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              disabled={loader}
              style={{
                color: "gray",
              }}>
              Cancel
            </Button>

            <LoadingButton
              loading={loader}
              // loadingPosition="end"
              autoFocus
              onClick={deleteRec}
              style={{
                color: "rgb(136, 172, 47)",
              }}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <nav className='sidebar dark'>
          <div>
            <header
              style={{
                margin: "10px",
                padding: "10px",
                paddingLeft: "50px",
                border: "1px dashed #676464",
                borderRadius: "13px",
              }}>
              {isAuthenticated && <Progress_bar bgcolor='#99ccff' progress={usageValue} /> }
              <Button
                variant='outlined'
                style={{
                  color: "rgb(136, 172, 47)",
                  border: "1px solid rgb(136, 172, 47)",
                }}
                endIcon={<CloudUploadIcon />}
                onClick={() => {
                  if(isAuthenticated){
                    setCreate(true);
                  }
                  else{
                    setLoginModalActive(true);
                  }
                  
                }}>
                Upload Document
              </Button>
            </header>

            <div></div>
            <Accordion
              expanded={activeDocument === "Sample"}
              style={{
                background: "transparent",
                color: "#fff",
                border: "1px solid #8b8a87",
                borderRadius: "15px",
                margin: "5px",
              }}
              onChange={handleExpandChange("Sample")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}>
                Sample Documents
              </AccordionSummary>
              <AccordionDetails style={{ padding: "2px 10px 10px" }}>
                {docList()}
              </AccordionDetails>
            </Accordion>

            {isAuthenticated &&
            <Accordion
              expanded={activeDocument === "My"}
              style={{
                background: "transparent",
                color: "#fff",
                border: "1px solid #8b8a87",
                borderRadius: "15px",
                margin: "5px",
              }}
              onChange={handleExpandChange("My")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{
                  fontWeight: "bold",
                }}>
                My Documents &nbsp;{" "}
                <Tooltip title='Refresh'>
                 
                    <RefreshIcon
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation()
                        setRefresh(!refresh);
                      }}
                    />
                
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "2px 10px 10px" }}>
                {docList()}
              </AccordionDetails>
            </Accordion>
            }
           
          </div>

          <div>
            <header
              style={{
                margin: "10px",
                padding: "10px",
                paddingLeft: "10px",
                border: "1px dashed #676464",
                borderRadius: "13px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <span style={{ color: "white" }}>Explore further </span>
              <Button
                variant='outlined'
                size='small'
                style={{
                  color: "rgb(136, 172, 47)",
                  border: "1px solid rgb(136, 172, 47)",
                  marginLeft: "15px",
                }}
                onClick={(e) => {
                  window.open("https://www.adeptia.com/about/contact-us");
                }}>
                Contact Us
              </Button>
            </header>
          </div>
        </nav>
        <section className='home'>
          {!initialLoader && !emptyData && !tryOut && (
            <ChatBot rec={currInteract} activeDocument={activeDocument} />
          )}

          {initialLoader && (
            <div className='processingDiv'>
              <div className='processingContainer'>
                <div className='text-container'>
                  <span style={{ marginBottom: "10px" }}>
                    Sit back and relax as we diligently retrieve your vibrant
                    database documents. 🌐✨{" "}
                  </span>
                  <span style={{ marginBottom: "10px" }}>
                    {" "}
                    Your data is about to take center stage!{" "}
                  </span>
                  <ColorRing
                    height='80'
                    width='80'
                    ariaLabel='blocks-loading'
                    wrapperStyle={{}}
                    wrapperClass='blocks-wrapper'
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          {emptyData && !tryOut && (
            <div className='processingDiv'>
              <div className='processingContainer'>
                <div className='text-container'>
                  <span style={{ marginBottom: "10px" }}>
                    Try uploading a document to kickstart the interaction. 🎨✨
                  </span>
                  <span style={{ marginBottom: "10px" }}>
                    {" "}
                    Your data adventure is just a upload away!{" "}
                  </span>
                  <Button
                    variant='outlined'
                    style={{
                      color: "rgb(136, 172, 47)",
                      border: "1px solid rgb(136, 172, 47)",
                    }}
                    endIcon={<CloudUploadIcon />}
                    onClick={() => {
                      setCreate(true);
                    }}>
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default KnowWrapper;

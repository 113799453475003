
let locationValue = window.location.origin;
let origin = window.location.origin;
let Runtime = window.parent?.Runtime;
let loggedInUser;
let obj = {
  userName : 'Admin'
}

if(Runtime !== undefined){
  loggedInUser = Runtime.getLoggedInUser();
  obj.userName = loggedInUser.firstName+' '+loggedInUser.lastName;
}


if(window.location.origin === 'http://localhost:3000'){
    locationValue = 'http://localhost:8080/';
}
else{
  locationValue = locationValue+'/'
}  


export const user = {

 userName: obj.userName
}


export const URL = {

    KNOWLEDGEBASE : locationValue+'rest/knowledgebase/getKnowledgeBase',
    ADDTAG:locationValue+'postAvailableTag',
    GETTAG:locationValue+'getAvailableTag',
    AISTORE:locationValue+'api/knowledgebaseStore',
    PUSHRECORD: locationValue+'rest/knowledgebase/processKnowledgeData',
    UNSTRUCTURED:locationValue+'rest/unstructured/convert/',
    STATUS: locationValue+'api/status',
    DELETE: locationValue+'rest/knowledgebase/deleteKnowledgeBase',
    KNOWLEDGEBASEBYTAG:locationValue+'rest/interact/byUserTag',
    KNOWLEDGEBASEBYFILE:locationValue+'rest/interact/byDataSource/',
    USERUPDATE:locationValue+'rest/user/update',
    GETUSER:locationValue+'rest/user/getUser',
    MANUALUPDATE:locationValue+'rest/user/updateUser',
    STATUSUPDATE:locationValue+'rest/knowledgebase/updateStatus',
    DELETEUSER:locationValue+'rest/user/deleteUser',
    getConfig:locationValue+'getConfig',
    SAMPLEDOCLIST:locationValue+'shared/getData',
    SAMPLEINTERACTION:locationValue+'shared/byDataSource/',
    SAMPLEIDP:locationValue+'unstructured/convert/',
}

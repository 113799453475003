import projectStyles from "./style.module.css";
import styles from "./FileProcess.module.css";
import FileDrag from "./FileDrag";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { URL, user } from "../../UrlConstants";
import { ColorRing } from "react-loader-spinner";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { Modal, ButtonToolbar, Placeholder } from "rsuite";
import { toast } from "react-toastify";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingButton from "@mui/lab/LoadingButton";
import ReplayIcon from "@mui/icons-material/Replay";
import posthog from "posthog-js";
import AceEditor from "react-ace";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fetchUser } from "../Helper";
import { Drawer, Checkbox } from "rsuite";
import Jsonformat from "./JsonFormatDialog";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PdfViewer from "./ReactPdf";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { Flex } from "@mantine/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FeedBackModal from "../FeedBackModal";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useSessionManager } from "../../SessionManagerProvider";
import LoginModal from "../LoginModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});
theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    adeptia: theme.palette.augmentColor({
      color: {
        main: "#88AC2F",
      },
      name: "adeptia",
    }),
  },
});

const FileProcess = (props) => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("json"); // default to JSON
  const [output, setOutput] = useState(null);
  const [loader, setLoader] = useState(false);
  const [response, setResponse] = useState("");
  const [startOver, setStartOver] = useState(false);
  const [drawerView, setDrawerView] = useState(false);
  const [drawerSize, setDrawerSize] = useState("lg");
  const [currentUsage, setCurrentUsage] = useState({ curr: 0, avail: 0 });
  const [reset, setReset] = useState(false);
  const [formatJsonCheck, setFormatJsonCheck] = useState(false);
  const [formatDialog, setFormatDialog] = useState(false);
  const [reqJsonFormat, setReqJsonFormat] = useState("");
  const [viewOutput, setViewOutput] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openFeedback, setFeedback] = useState(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [dusage, setdusage] = useState(false);
  const [loginModalactive,setLoginModalActive] = useState(false);
  
  const history = useHistory();
  const {
    mobileError,setIsMobileError
  } = useSessionManager();

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleStartOver = () => {
    setFile(null);
    setStartOver(false);
    setViewOutput(false);
    setResponse(null);
    setReqJsonFormat("");
    setFormatJsonCheck(false);
  };
  const createFile = (content, filename) => {
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadfile = () => {
    // setDrawerSize("lg");
    // setDrawerView(true);
    setViewOutput(true);
    posthog.capture("Preview the Strucutred file");
    // createFile(response,file.name+"_"+fileType+'.'+fileType);
  };

  const downloadExternalfile = () => {
    posthog.capture("Downloaded the Strucutred file");
    createFile(response, file.name + "_" + fileType + "." + fileType);
  };

  const handleRunClick = async (e) => {
    
    posthog.capture("Executed the unstructure file");
    setLoader(true);
    const jsonminify = require("jsonminify");
    const formData = new FormData();
    formData.append("file", file);

    let axURL = URL.SAMPLEIDP;
    let headers = {
      "Content-Type": "multipart/form-data",
    }
    if(isAuthenticated){
      const accessToken = await getAccessTokenSilently();
      axURL = URL.UNSTRUCTURED;
       headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + accessToken,
      }
    }

    if (fileType === "json" && formatJsonCheck) {
      formData.append("data", jsonminify(reqJsonFormat));
    }

    try {
    
      const response = await axios
        .post(axURL + fileType, formData, {
          headers: headers
        })
        .then((response) => {
          if (response.status === 200 || response.status === 202) {
            setReset(!reset);
            if (fileType === "json") {
              setResponse(JSON.stringify(response.data, null, 2));
              // createFile(,file.name+"_"+fileType+'.'+fileType)
            } else {
              setResponse(response.data);
              // createFile(response.data,file.name+"_"+fileType+'.'+fileType)
            }
            setLoader(false);
            setStartOver(true);
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status === 429) {
            setdusage(true);
          } else {
            toast.error(error.response.data, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 7000, // Close the toast after 3 seconds
            });
          }
        });
    } catch (error) {
      setLoader(false);
      console.error("Error uploading file", error);
    }
  };

  const getUsage = async () => {
    const token = await getAccessTokenSilently();
    const getUser = await fetchUser(token);
    if (getUser !== null) {
      setCurrentUsage({
        curr: getUser.counterValue,
        avail: getUser.dailyQuota,
      });
    }
  };

  useEffect(() => {

    
    if(isMobile){
      setIsMobileError(true);
    }
    if(!isAuthenticated){
      const fetchFileContent = async () => {
      
          const response = await fetch('SampleDocs/Sample-Invoice.pdf').then(response => {
            response.blob().then(blob => {
            setFile(blob);
            });
            //window.location.href = response.url;
        })
      
    }
    fetchFileContent();
  }
  else{
    getUsage();
  }
    
  }, [reset]);

  posthog.capture("Extract Data page");

  return (
    <React.Fragment>
      <FeedBackModal
        open={openFeedback}
        setOpen={setFeedback}
        target='Intelligent Document Processing'
      />
      <Jsonformat
        openFlag={formatDialog}
        setOpenFlag={setFormatDialog}
        format={reqJsonFormat}
        setFormat={setReqJsonFormat}
      />

      <LoginModal loginModalactive={loginModalactive} setLoginModalActive={setLoginModalActive} />

      <Modal open={open} onClose={handleClose} size='sm'>
        <Modal.Header></Modal.Header>

        <Modal.Body>
          {/* <div style={{textAlign: 'center',fontWeight: 600,fontSize: 'medium'}}>You can quickly extract information from your business documents. Simply upload your unstructured data (PDF or image) and convert into structured data (CSV or JSON).</div> */}

          <div
            style={{
              padding: 10,
              background: "white",

              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
                display: "flex",
              }}>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div style={{ flex: "1 1 0", textAlign: "center" }}>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    With
                  </span>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "700",
                      wordWrap: "break-word",
                    }}>
                    {" "}
                    Intelligent Document Processing (IDP){" "}
                  </span>
                  <span
                    style={{
                      color: "#0A0F0A",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    you can quickly extract information from your business
                    documents (such as forms, invoices, receipts, images etc.)
                    directly into a structured data format. Simply upload your
                    unstructured data (PDF or image) and convert into structured
                    data (CSV or JSON).
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal open={dusage} onClose={() => setdusage(false)} size='sm'>
        <Modal.Header></Modal.Header>

        <Modal.Body>
          {/* <div style={{textAlign: 'center',fontWeight: 600,fontSize: 'medium'}}>You can quickly extract information from your business documents. Simply upload your unstructured data (PDF or image) and convert into structured data (CSV or JSON).</div> */}

          <div
            style={{
              background: "white",

              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex",
            }}>
            <div
              style={{
                alignSelf: "stretch",
                height: 144,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
                display: "flex",
              }}>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div
                  style={{
                    textAlign: "center",
                    color: "#0A0F0A",
                    fontSize: 30,
                    fontFamily: "Inter",
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}>
                  Quota Reached!
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                }}>
                <div style={{ flex: "1 1 0", textAlign: "center" }}>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    Contact{" "}
                  </span>
                  <span
                    onClick={() => {
                      window.open("https://www.adeptia.com/about/contact-us");
                    }}
                    style={{
                      color: "#88AC2F",
                      fontSize: 16,
                      cursor: "pointer",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      textDecoration: "underline",
                      wordWrap: "break-word",
                    }}>
                    Adeptia sales
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    to remove limits or see more capabilities for automatic data
                    extraction and processing
                    <br />
                    <br />
                  </span>
                  <span
                    style={{
                      color: "#7D7D7D",
                      fontSize: 14,
                      fontFamily: "Inter",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}>
                    Up to 20 files per day and 10 MB per file
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: window.innerHeight - 80,
          padding: "10px",
          paddingTop:"1px"
        }}>


<div style={{ height: "85px" }}>
        <h1 className={styles.text}>
          <span>Intelligent Document Processing </span>
        </h1>
        <div style={{display:"flex",marginTop:'5px'}}>
        <div
          className={styles.overviewDivWrapper}
          style={{ marginLeft: "60px" }}
          onClick={() => history.push("/home")}>
             <ArrowBackIcon />
        </div>
        <div
          className={styles.overviewDivWrapper}
          style={{ marginLeft: "10px" }}
          onClick={() => setOpen(true)}>
          <div>Feature Overview</div>
        </div>
        <div
          className={styles.overviewDivWrapper}
          style={{ marginLeft: "10px" }}
          onClick={() => setFeedback(true)}>
          <div>Provide Feedback</div>
        </div>
      </div>
      </div>
       
        <div
          style={{
            border: "1px solid #ebe8e8",
            height: "100%",
            width: "100%",
            margin: "auto",
            display: "flex",
          }}>
          <div
            style={{
              borderRight: "1px solid #ebe8e8",
              height: "100%",
              width: "50%",
              padding: "10px",
            }}>
            <FileDrag
              method={setFile}
              file={file}
              setFile={setFile}
              startOver={viewOutput}
              setStartOver={handleStartOver}
              setLoginModalActive={setLoginModalActive}
              key={1}
            />
          </div>
          <div style={{ marginTop: "10px", width: "50%" }}>
            {!viewOutput && (
              <div
                className={styles["container1"]}
                style={{ marginLeft: "20px" }}>
                <div className={styles["container2"]}>
                  {isAuthenticated && <span className={styles["text2"]}>
                    Your current quota for daily usage is{" "}
                    <b>
                      {currentUsage.curr} out of {currentUsage.avail}
                    </b>{" "}
                    documents
                  </span>}
                  <div className={styles["container3"]}>
                    <FormControl style={{ width: "80%" }} size='small'>
                      <InputLabel id='structure-required-label'>
                        Output Format Required
                      </InputLabel>
                      <Select
                        labelId='structure-required-labels'
                        value={fileType}
                        onChange={handleFileTypeChange}
                        label='Output Format Required'>
                        <MenuItem value='json'>JSON</MenuItem>
                        <MenuItem value='csv'>CSV</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {fileType === "json" && (
                    <div
                      style={{
                        marginTop: "1vh",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      Want output in your specific JSON format?
                      <Checkbox
                        checked={formatJsonCheck}
                        onChange={() => setFormatJsonCheck(true)}>
                        Yes{" "}
                        {formatJsonCheck && reqJsonFormat === "" && (
                          <span
                            onClick={() => {
                              setFormatDialog(true);
                            }}
                            style={{ marginLeft: "2vw" }}>
                            <a>Define Output Format</a>
                          </span>
                        )}
                        {formatJsonCheck && reqJsonFormat !== "" && (
                          <span
                            onClick={() => {
                              setFormatDialog(true);
                            }}
                            style={{ marginLeft: "2vw" }}>
                            <a>View Selected Output Format</a>
                          </span>
                        )}
                      </Checkbox>
                      <Checkbox
                        checked={!formatJsonCheck}
                        onChange={() => setFormatJsonCheck(false)}>
                        {" "}
                        No
                      </Checkbox>
                    </div>
                  )}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {!startOver && (
                      <ThemeProvider theme={theme}>
                        <LoadingButton
                          variant='contained'
                          color='adeptia'
                          loading={loader}
                          disabled={
                            file === null ||
                            (fileType === "json" &&
                              formatJsonCheck &&
                              reqJsonFormat === "")
                          }
                          // endIcon={<DirectionsRunIcon />}
                          loadingPosition='end'
                          style={{
                            marginTop: "20px",
                            alignSelf: "center",
                            color: "white",
                          }}
                          onClick={handleRunClick}>
                          Process
                        </LoadingButton>
                      </ThemeProvider>
                    )}

                    {startOver && (
                      <Button
                        variant='contained'
                        color='primary'
                        style={{
                          marginLeft: "20px",
                          marginTop: "20px",
                          alignSelf: "center",
                          background: "#88AC2F",
                        }}
                        onClick={() => {
                          if(isAuthenticated){
                            setFile(null);
                          }
                          setStartOver(false);
                          setResponse(null);
                          setReqJsonFormat("");
                          setFormatJsonCheck(false);
                          setViewOutput(false);
                        }}>
                        Start Over
                        <ReplayIcon />
                      </Button>
                    )}

                    {response && (
                      <Button
                        variant='outlined'
                        style={{
                          marginLeft: "20px",
                          marginTop: "20px",
                          alignSelf: "center",
                          color: "#88AC2F",
                          border: "1px solid #88AC2F",
                        }}
                        onClick={downloadfile}
                        endIcon={<VisibilityIcon />}>
                        View Output
                      </Button>
                    )}
                  </div>
                </div>
                <div className={styles["container4"]}>
                  <span className={styles["text3"]}>
                    <b>Note</b> – Your files uploaded here are not saved or
                    stored <br />
                    <b>Limitations</b> – Up to 20 files per day and 10 MB per
                    file <br />
                    <b>Example</b> – To try this feature quickly, you may
                    download and try this{" "}
                    <a href='docs/Sample-Invoice.pdf' target='_blank'>
                      sample invoice PDF file
                    </a>{" "}
                    <br />
                    Contact{" "}
                    <a
                      href='https://www.adeptia.com/about/contact-us'
                      target='_blank'>
                      Adeptia sales{" "}
                    </a>{" "}
                    to remove limits or see more capabilities for automatic data
                    extraction and processing
                  </span>
                </div>
              </div>
            )}

            {viewOutput && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  marginLeft: "10px",
                }}>
                <div
                  style={{
                    display: "flex",
                    float: "right",
                    marginBottom: "10px",
                    justifyContent: "flex-end",
                    alignItems: "end",
                    marginRight: "20px",
                  }}>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      marginRight: "10px",
                      background: "#88AC2F",
                    }}
                    onClick={() => {
                      if(isAuthenticated){
                        setFile(null);
                      }
                      setStartOver(false);
                      setResponse(null);
                      setReqJsonFormat("");
                      setFormatJsonCheck(false);
                      setViewOutput(false);
                    }}>
                    Start Over
                    <ReplayIcon />
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      marginRight: "10px",
                      background: "#88AC2F",
                    }}
                    onClick={downloadExternalfile}>
                    Download Output File
                    <FileDownloadIcon />
                  </Button>

                  <Tooltip title='Copy Content'>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(response);
                      }}>
                      <ContentPasteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <AceEditor
                  placeholder='Placeholder Text'
                  mode='json'
                  theme='tomorrow'
                  name='blah2'
                  readOnly
                  style={{
                    width: "100%",
                    height: "100%",
                    borderTop: "1px solid #f3eded",
                  }}
                  showGutter={true}
                  highlightActiveLine={true}
                  fontSize={14}
                  showPrintMargin={false}
                  wrapEnabled
                  value={response}
                  setOptions={{
                    showLineNumbers: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileProcess;

import React from "react";
import { Tooltip } from "@mui/material";
import "./Terms&Condition.css";
import { Footer } from "./Footer";

const TermsCondition = () => {
  const setChange = (e) => {};

  return (
    <div className='container'>
      <div className='topic'>TERMS OF SERVICE</div>
      <div className='content'>
        <div className='text-content'>
          <div
            className='profilehome text'
            style={{
              fontSize: "18px",
              fontWeight: 500,
              color: "black",
            }}
            >
            <div className='title'>Introduction</div>
            <p>
              Welcome to labs.adeptia.com and associated application, Adeptia
              Labs (collectively the “Website”). This Website is owned and
              operated by Adeptia, Inc. (“Adeptia”, &quot;us”, “we”, or “our”).
              By visiting our Website and accessing the information, resources,
              services, products, and tools we provide, you acknowledge that you
              have read, understand and agree to accept and adhere to the
              following terms and conditions as stated in this policy (hereafter
              referred to as “User Agreement”) and all applicable laws and
              regulations, including export and re-export control laws and
              regulations, along with the terms and conditions stated in our
              Privacy Notice (please refer to the Privacy Section below for more
              information).
            </p>

            <p>This User Agreement is in effect as of February 1, 2024.</p>

            <p>
              We reserve the right to change this User Agreement from time to
              time without notice. Adeptia may also make improvements or changes
              to the Website or Resources (as defined in the Responsible Use and
              Conduct Section) at any time without notice. You acknowledge and
              agree that it is your responsibility to review this User Agreement
              periodically to familiarize yourself with any modifications. Your
              continued use of this Website after such modifications shall
              constitute acknowledgment and agreement of the modified terms and
              conditions.
            </p>

            <p>
              <b>
                IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, IMMEDIATELY
                STOP ACCESSING THIS WEBSITE AND DO NOT USE ANY ADEPTIA SERVICES.
              </b>
            </p>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Responsible Use and Conduct</div>
            <p>
              In order to use this Website, you must be an individual of at
              least 18 years of age. Persons under 18 years of age may not use
              this Website. You must not be barred from receiving products or
              services under United States law, including residents of countries
              facing sanctions from the U.S. Office of Foreign Asset Control
              (“OFAC”). Such countries currently include at least Cuba, North
              Korea, Iran, Syria and Sudan; Adeptia may not provide services to
              residents of these countries.
            </p>
            <p>
              By visiting our Website and accessing the information, resources,
              services, products, and tools we provide for you, either directly
              or indirectly (hereafter referred to as “Resources”), you agree to
              use these Resources only for the purposes intended as permitted by
              (a) the terms of this User Agreement, and (b) applicable laws,
              regulations and generally accepted online practices or guidelines.
              You also agree to provide only information that is accurate, true,
              current and complete.
            </p>

            <p>Wherein, you understand that:</p>
            <ol>
              <li>
                <p>
                  In order to access our Resources, you may be required to
                  provide certain information about yourself (such as
                  identification, contact details, etc.) as part of the
                  registration process, or as part of your ability to use the
                  Resources. You agree that any information you provide shall
                  always be accurate, correct, and up to date.
                </p>
              </li>
              <li>
                <p>
                  You are responsible for maintaining the confidentiality of any
                  login information associated with any account you use to
                  access our Resources. Accordingly, you are responsible for all
                  activities that occur under your accounts.
                </p>
              </li>
              <li>
                <p>
                  Accessing (or attempting to access) any of our Resources by
                  any means other than through the means we provide is strictly
                  prohibited. You specifically agree not to access (or attempt
                  to access) any of our Resources through any automated,
                  unethical, or unconventional means, including but not limited
                  to the use of framing, metatags, or other hidden text
                  techniques or technologies.
                </p>
              </li>
              <li>
                <p>
                  Engaging in any activity that disrupts or interferes with our
                  Website or Resources, including the servers and/or networks to
                  which our Resources are located or connected, is strictly
                  prohibited. Such activity includes but is not limited to
                  overloading, “flooding,” “spamming,” “mail bombing,”
                  “crashing” or submitting viruses to the Website or our
                  Resources.
                </p>
              </li>
              <li>
                <p>
                  Attempting to copy, duplicate, reproduce, sell, trade, or
                  resell our Website or Resources is strictly prohibited. This
                  prohibition prevents you from using the Website or our
                  Resources to advertise, solicit, or otherwise use the Website
                  for any commercial, political, or religious purpose, or to
                  attempt to compete with Adeptia, directly or indirectly. This
                  also prevents you from attempting to collect the personal
                  information, statements, data, or content of any users of the
                  Website and from hyperlinking to the Website without our prior
                  written consent.
                </p>
              </li>
              <li>
                <p>
                  You are solely responsible for any consequences, losses, or
                  damages that we may directly or indirectly incur or suffer due
                  to any unauthorized activities conducted by you, as explained
                  above, and you may incur criminal or civil liability.
                </p>
              </li>
              <li>
                <p>
                  We may provide various open communication tools on our
                  Website, such as blog comments, blog posts, public chat,
                  forums, message boards, newsgroups, product ratings and
                  reviews, various social media services, etc. You understand
                  that generally we do not pre-screen or monitor the content
                  posted by users of these various communication tools, which
                  means that if you choose to use these tools to submit any type
                  of content to our Website, then it is your personal
                  responsibility to use these tools in a responsible and ethical
                  manner. By posting information or otherwise using any open
                  communication tools as mentioned, you agree that you shall not
                  upload, post, share, or otherwise distribute any content that:
                </p>
                <ol type='i'>
                  <li>
                    Is illegal, threatening, defamatory, libelous, derogatory,
                    abusive, harassing, degrading, intimidating, pornographic,
                    indecent, fraudulent, deceptive, invasive of privacy or
                    publicity rights, racist, or contains any type of
                    suggestive, inappropriate, or explicit language, or gives
                    rise to civil or criminal liability or otherwise violates
                    any applicable law;
                  </li>
                  <li>
                    Contains software or other materials that contain viruses,
                    worms, time bombs, Trojan horses, or other harmful or
                    disruptive components, political campaign materials, chain
                    letters, mass mailings, spam mail, any robot, spider, site
                    search or retrieval applications, or other manual or
                    automatic devices or processes to retrieve, index, “data
                    mine,” or in any way reproduce or circumvent the
                    navigational structure or presentation of the Website or
                    Resources;
                  </li>
                  <li>
                    Harvests or collects information about Website visitors;
                  </li>
                  <li>
                    Infringes on any trademark, patent, trade secret, copyright,
                    or other proprietary right of any party;
                  </li>
                  <li>
                    Contains any type of unauthorized or unsolicited
                    advertising, commercial solicitations, or promotional
                    materials relating to the website or online services which
                    are competitive with Adeptia and/or the Website and
                    Resources;
                  </li>
                  <li>
                    Impersonates any person or entity, including any of our
                    employees or representatives; and,
                  </li>
                  <li>
                    Contains information that identifies, relates to, describes,
                    is reasonably capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a
                    particular individual, consumer or household (“Personal
                    Information”). To the extent that any data that is not
                    identifiable to a particular individual, consumer, or
                    household, such as aggregated data, is stored or associated
                    with Personal Information, it shall be treated as Personal
                    Information.
                  </li>
                </ol>
                <p>
                  We have the right at our sole and absolute discretion to
                  remove any content that we feel does not comply with this User
                  Agreement, along with any content that we feel is otherwise
                  offensive, harmful, objectionable, inaccurate, or violates any
                  third party intellectual property or privacy rights. We are
                  not responsible for any delay or failure in removing such
                  content. If you post content that we choose to remove, you
                  hereby consent to such removal, and consent to waive any claim
                  against us.
                </p>
              </li>
              <li>
                <p>
                  We do not assume any liability for any content posted by you
                  or any other third-party users of our Website, including
                  Personal Information. We do not accept or consider unsolicited
                  ideas of any kind, including, without limitation, ideas or
                  suggestions relating to new or improved products,
                  enhancements, names or technologies, advertising and marketing
                  campaigns, plans, or other promotions. However, any content
                  posted by you using any open communication tools on our
                  Website, provided that it doesn’t violate or infringe on any
                  third party intellectual property rights, becomes the property
                  of Adeptia, and as such, gives us a perpetual, irrevocable,
                  worldwide, royalty-free, exclusive license to reproduce,
                  modify, adapt, translate, publish, publicly display,
                  distribute, and otherwise use such content as we see fit. This
                  only refers and applies to content posted via open
                  communication tools as described and does not refer to
                  information that is provided as part of the registration
                  process, is necessary in order to use our Resources, or any
                  third-party Personal Information. All information provided as
                  part of our registration process is covered by our Privacy
                  Notice.
                </p>
              </li>
              <li>
                <p>
                  You agree to indemnify and hold harmless Adeptia and its
                  parent company and affiliates, and their directors, officers,
                  managers, employees, donors, agents, and licensors, from and
                  against all losses, expenses, damages, and costs, including
                  reasonable attorneys’ fees, resulting from any violation of
                  this User Agreement, the disclosure, use, collection, sharing,
                  or selling of Personal Information, or the failure to fulfill
                  any obligations relating to your account incurred by you or
                  any other person using your account. We reserve the right to
                  take over the exclusive defense of any claim for which we are
                  entitled to indemnification under this User Agreement. In such
                  an event, you shall provide us with such cooperation as is
                  reasonably requested by us.
                </p>
              </li>
              <li>
                <p>
                  Any content downloaded or otherwise obtained through the use
                  of our Website or Resources is done at your own discretion and
                  risk, and that you shall be solely responsible for any damage
                  to your computer or other devices and for any loss of data
                  that may result from the download of such content;
                </p>
              </li>
              <li>
                <p>
                  You shall not upload, disclose, use, collect, share, or sell
                  any Personal Information using our Website or Resources and
                  that any upload, use, collection, sharing, or selling of
                  Personal Information is done at your own discretion and risk,
                  and that you are solely responsible for any violation of third
                  party privacy rights that may result from such conduct; no
                  information or advice, whether expressed, implied, oral or
                  written, obtained by you from Adeptia or through any Resources
                  we provide shall create any warranty, guarantee, or conditions
                  of any kind, as set forth in the Disclaimer of Warranty
                  Section, below; and,
                </p>
              </li>
              <li>
                <p>
                  The Website may contain links to other websites maintained by
                  third parties, and that these links are provided solely as a
                  convenience to you. Adeptia expressly disclaims any
                  representations regarding the content or accuracy of materials
                  on such websites or the privacy practices of those websites.
                  We do not endorse or represent any products or services that
                  may be marketed on such websites. If you decide to access
                  websites maintained by third parties, you do so at your own
                  risk.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text' 
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Fees and Refunds</div>
            <ol>
              <li>
                <p>
                  <b>Fees:</b> Some features of the Website and Resources may
                  only be accessed and used upon the payment of applicable fees
                  (“Fees”). Fees may vary depending on your usage in accordance
                  with our current pricing policy, which is subject to change
                  without notice to you. If you do not initially register for a
                  version of the Website and Resources that requires the payment
                  of Fees, you shall nonetheless be permitted to use all of the
                  features of the Website and Resources for up to the number of
                  free connections on Adeptia software (non-Hybrid Integration
                  Option) as defined in our current pricing policy and subject
                  to the License terms (as defined in the Ownership and
                  Intellectual Property Section). Upon exceeding the allowed
                  number of free connections, we will ask you to supply a credit
                  card to continue adding connections to the product. If you
                  wish to be billed for service, please{" "}
                  <a
                    href='https://www.adeptia.com/adeptia-connect-demo'
                    target='_blank'>
                    contact us
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  <b>Refunds:</b> Our service shall be billed in accordance with
                  the usage model defined in our current pricing policy on a
                  non-prorated basis. No refunds shall be issued.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Ownership and Intellectual Property</div>

            <ol>
              <li>
                <p>
                  The content and information on the Website and Resources, as
                  well as the infrastructure used to provide both and the
                  underlying intellectual property therein, is proprietary to
                  and owned by Adeptia. Adeptia grants you a limited, personal,
                  nontransferable, non-sublicensable, nonexclusive, revocable
                  license to access and use the Website and Resources for
                  testing and evaluation purposes only (the “License”) pursuant
                  to this User Agreement and any additional terms and policies
                  Adeptia may in the future set forth. You agree that we shall
                  retain all rights to the Website and Resources except for
                  those rights granted to you in this User Agreement, and that
                  nothing herein shall serve to transfer ownership or title of
                  the Website or Resources from us to you.
                </p>
              </li>
              <li>
                <p>
                  The License shall commence upon your first use of the Website
                  and shall terminate ninety (90) days from the date of your
                  first use of the Website. Upon termination of the License, you
                  shall immediately cease any and all use of the Website and
                  Resources and immediately return any Resources in your
                  possession.
                </p>
              </li>
              <li>
                <p>
                  All content and materials available on the Website, including
                  but not limited to text, graphics, domain, Website name, code,
                  data, methods, processes, images and logos are the
                  intellectual property of Adeptia, and are protected by
                  applicable intellectual property law. You agree not to
                  reproduce, distribute, disclose, create derivative works from,
                  publicly display, publicly perform, license, sell or re-sell
                  any data, content, information, software, products or services
                  obtained from or through the Website or the Resources without
                  the express permission of Adeptia. In addition, you may not
                  make any use that exceeds or violates this User Agreement. Any
                  inappropriate use, including but not limited to the
                  reproduction, distribution, display or transmission of any
                  content on this Website is strictly prohibited unless
                  specifically authorized by Adeptia.
                </p>
              </li>
              <li>
                <p>
                  The use authorized under this User Agreement is non-commercial
                  in nature and you may not sell any content you access on or
                  through this Website or the Resources.
                </p>
              </li>
              <li>
                <p>
                  You agree to use best efforts, and shall cause any employees,
                  representatives and agents to use best efforts, to protect the
                  confidentiality of the Website, Resources, and any proprietary
                  information contained therein, but in no case less than the
                  same level of security and care as it takes to protect your
                  own confidential information. You agree to execute, and shall
                  cause any employees, representatives, and agents having access
                  to the Website or Resources to execute, confidentiality
                  agreements as we may reasonably request. The covenants and
                  obligations in this Section shall survive expiration of the
                  License or this User Agreement.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text' 
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}>
            <div className='title'>Privacy</div>
            <p>
              Your privacy is very important to us, which is why we’ve created a
              separate Privacy Notice in order to explain in detail how we
              collect, manage, process, secure, and store your private
              information, including the use of “cookies”. Our Privacy Notice is
              included under the scope of this User Agreement. To read our
              Privacy Notice in its entirety, click here.
            </p>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Limitation of Liability</div>
            <p>
              <b>
                IN CONJUNCTION WITH THE DISCLAIMER OF WARRANTIES AS EXPLAINED
                BELOW, YOU UNDERSTAND AND AGREE THAT ANY CLAIM AGAINST ADEPTIA
                SHALL BE LIMITED TO THE AMOUNT YOU PAID, IF ANY, FOR USE OF
                PRODUCTS AND/OR SERVICES CREATED AND SOLD BY ADEPTIA. TO THE
                FULLEXT EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
                ADEPTIA BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY LOSS OR DAMAGES
                OF ANY TYPE WHATSOEVER RELATED TO OR ARISING OUT OF THE
                RESOURCES OR WEBSITE OR ANY USE OF THIS WEBSITE, OR OF ANY SITE
                OR RESOURCES LINKED TO, REFERENCED, OR ACCESSED THROUGH THIS
                WEBSITE, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY
                MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING WITHOUT
                LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST
                SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF ADEPTIA IS
                EXPRESSLY ADVISED OF THE POSSIBILITY OF 6 SUCH DAMAGES, OR AS A
                RESULT OF ANY CHANGES, DATA LOSS, CORRUPTION, CANCELLATION, LOSS
                OF ACCESS OR DOWNTIME. THIS EXCLUSION AND WAIVER OF LIABILITY
                APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED IN CONTRACT,
                WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES. YOU ARE USING THE
                WEBSITE AT YOUR OWN RISK.
              </b>
            </p>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Termination of Use</div>
            <p>
              You agree that we may, at our sole discretion, suspend or
              terminate your access to all or part of our Website and Resources
              with or without notice and for any reason, including, without
              limitation, breach of this User Agreement. Any suspected illegal,
              fraudulent or abusive activity may be grounds for terminating your
              relationship and may be referred to appropriate law enforcement
              authorities. Upon suspension or termination, your right to use the
              Website and Resources we provide shall immediately cease, and we
              reserve the right to remove or delete any information that you may
              have on file with us, including any account or login information
              and any third party Personal Information.
            </p>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Governing Law</div>
            <p>
              This Website is controlled by Adeptia from our offices located in
              the state of Illinois, USA. It can be accessed by most countries
              around the world. As each country has laws that may differ from
              those of Illinois, by accessing our Website, you agree that the
              statutes and laws of Illinois, without regard to the conflict of
              laws and the United Nations Convention on the International Sales
              of Goods, shall apply to all matters relating to the use of this
              Website and Resources and the purchase of any products or services
              through this Website.
            </p>
            <p>
              Furthermore, any action to enforce this User Agreement shall be
              brought in the federal or state courts located in Illinois, USA.
              You hereby agree to personal jurisdiction by such courts, and
              waive any jurisdictional, venue, or inconvenient forum objections
              to such courts.
            </p>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Disclaimer of Warranty</div>
            <b>
              USE OF THIS WEBSITE IS AT YOUR SOLE RISK. ALL MATERIALS,
              INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES ARE
              PROVIDED “AS IS” AND “AS AVAILABLE” WITH NO WARRANTIES OR
              GUARANTIES WHATSOEVER. UNLESS OTHERWISE EXPRESSED, ADEPTIA
              EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL
              WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED,
              STATUTORY, AND OTHER WARRANTIES, GUARANTIES, OR REPRESENTATIONS,
              INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY
              RIGHTS. WITHOUT LIMITATION, ADEPTIA MAKES NO WARRANTY OR GUARANTEE
              THAT THIS WEBSITE SHALL BE UNINTERRUPTED, TIMELY, SECURE, OR
              ERROR-FREE, THAT THE INFORMATION OBTAINED BY USING OUR WEBSITE OR
              RESOURCES IS ACCURATE OR RELIABLE, OR THAT ADEPTIA WILL REPAIR OR
              CORRECT ANY DEFECT IN THE OPERATION OR FUNCTIONALITY OF THE
              WEBSITE OR RESOURCES. WHILE ADEPTIA HAS SAFEGUARDS IN PLACE TO
              PREVENT UNAUTHORIZED ACCESS OR INTERCEPTION OF PERSONAL
              INFORMATION, THERE IS NO ABSOLUTE GUARANTEE OF SECURITY. IN THE
              EVENT OF AN INTERCEPTION OR UNAUTHORIZED ACCESS, ADEPTIA SHALL NOT
              BE RESPONSIBLE FOR SUCH INTERCEPTION OR 7 UNAUTHORIZED ACCESS.
              ADEPTIA DOES NOT WARRANT THAT THE INFORMATION PROVIDED BY YOU OR
              ANY OTHER VISITOR SHALL BE FREE FROM INTERCEPTION OR UNAUTHORIZED
              ACCESS. IN TRANSMITTING ELECTRONIC COMMUNICATIONS TO ADEPTIA, YOU
              ARE REQUESTING THAT ADEPTIA SEND INFOMRATION TO OR CONTACT YOU
              REGARDING OUR PRODUCTS OR OUR SERVICES. ADEPTIA IS NOT RESPONSIBLE
              FOR DELAY, NON-DELIVERY OR NON-RECEIPT OF ANY INFORMATION
              EXCHANGED BETWEEN ADEPTIA AND YOU OR VICE-VERSA DUE TO INTERNET
              CONNECTIVITY PROBLEMS, MAN-MADE OR NATURAL DISASTERS, FORCE
              MAJEURE, COMPUTER MALFUNCTIONS OR INCORRECT CONTACT INFORMATION
              (INCLUDING, BUT NOT LIMITED TO EMAIL ADDRESSES, MAILING ADDRESSES,
              TELEPHONE, OR FAX NUMBERS) SUPPLIED BY YOU.
            </b>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Contact Information</div>

            <p>
              If you have any questions or comments about these, our Terms of
              Service, as outlined above, you can contact us at:
            </p>
            <address>
              Adeptia
              <br />
              332 S. Michigan Ave.
              <br />
              Unit LL-A105
              <br />
              Chicago, IL 60604, USA
              <br />
              <a href='mailto:website-inquiries@adeptia.com'>
                website-inquiries@adeptia.com
              </a>
            </address>
          </div>
        </div>
      </div>
      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>California Residents</div>

            <p>
              If you are a California resident, you may report complaints to the
              Complaint Assistance Unit of the Division of Consumer Services of
              the California Department of Consumer Affairs by contacting them
              in writing at 400 R Street, Sacramento, CA 95811, or by telephone
              at (800) 952-5210. Please also refer to our Privacy Notice.
            </p>
          </div>
        </div>
      </div>
      <div className='content'>
        <div className='text-content'>
          <div className='profilehome text'
           style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "black",
          }}
          >
            <div className='title'>Miscellaneous</div>

            <p>
              No waiver by Adeptia of any breach, default or failure to exercise
              any right allowed under this User Agreement is a waiver of any
              preceding or subsequent breach or default or a waiver or
              forfeiture of any similar or future rights under this User
              Agreement. The section headings used herein are for convenience
              only and shall be of no legal force or effect. If a court of
              competent jurisdiction holds any provision of this User Agreement
              invalid, such invalidity shall not affect the enforceability of
              any other provisions contained in this User Agreement, and the
              remaining portions of this User Agreement shall continue in full
              force and effect.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsCondition;

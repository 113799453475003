import React, {useEffect, useState} from "react"
import { Modal, ButtonToolbar, Button, RadioGroup, Radio, Placeholder } from 'rsuite';
import AceEditor from "react-ace";

import {  toast } from 'react-toastify';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";



const Jsonformat = (props) => {
  const [open, setOpen] = React.useState(true);
  
  const [value,setValue] = useState("");
  const handleClose = () => {
    props.setOpenFlag(false);
  }

  const handleClear =(e) => {
    setValue("")
  }

  const onChangeValue = (e) =>{
    setValue(e);
  }

  useEffect(() => {
    setValue(props.format)
  },[props.openFlag])



  const handleSubmit = () => {
            try {
                JSON.parse(value);
                props.setFormat(value);
                handleClose();
              } catch (error) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000, // Close the toast after 3 seconds
                  })
              }
  }



  return (
    
      <Modal backdrop='static' size = "md" open={props.openFlag} onClose={handleClose}>
          <Modal.Header>
        <b>Define your desired output JSON format (type or paste it here)</b> 
        </Modal.Header>

        <Modal.Body>
        <AceEditor
              
              mode="json"
              theme="tomorrow"
              name="blah2"
              style={{
                width:'100%',
                height:'60vh'

              }}
              showGutter={true}
              highlightActiveLine={true}
              onChange={onChangeValue}
              fontSize={14}
              showPrintMargin={false}
              wrapEnabled
              value={value}
              setOptions={{
              showLineNumbers: true,
             
         }}/>

        </Modal.Body>
        <Modal.Footer>
        <Button onClick={handleClear} appearance="subtle">
            Clear
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleSubmit} appearance="primary" style={{
            background: "#88AC2F"
          }}>
            Submit
          </Button>
        </Modal.Footer>
       
      </Modal>

  );
};

export default Jsonformat;
import { Modal, ButtonToolbar, Placeholder } from 'rsuite';
import React ,{useState} from 'react';
import { useSessionManager } from '../SessionManagerProvider';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import {  toast } from 'react-toastify';
import { URL } from "../UrlConstants";
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';

const Disclaimer = () => {
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const { user,getAccessTokenSilently,logout } = useAuth0();
  const[loader,setLoader] = useState(false);
  const history = useHistory();
  const {disclaimerDrawer,setDisclaimerDrawer,loggedInUser,setLoggedInUser} = useSessionManager();
  const handleOpen = value => {
    setSize(value);
    setOpen(true);
  };


  const updateUser = async() =>{
    const userData = {
        ...user,
        disclaimer:true
    };
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(URL.USERUPDATE, userData, {
      headers: {
        "Authorization":"Bearer "+accessToken
      }
    }).then((response) => {
        setLoggedInUser(response.data);
        setLoader(false);
        setDisclaimerDrawer(false);
                }).catch(error => {
                    setLoader(false);
                  toast.error("Error while accepting", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000, // Close the toast after 3 seconds
                  })
                });
  }


  const onAccept = () => {
    setLoader(true);
    updateUser();
  }

  return (
    <>
     
      <Modal overflow={true} size="md" open={disclaimerDrawer} >
        <Modal.Header closeButton={false}>
          <Modal.Title>Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        The information and technology provided by Adeptia ("we," "us," or "our") on https://labs.adeptia.com (the Site) is for general informational purposes only. All information and functionality on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site. Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information or data provided in the site. Your use of the site and reliance on any information or data on the site is solely at your own risk and you agree to these policies –
        <br />
        <a href='docs/AC-Labs-Terms and Conditions.pdf' target='_blank'>Terms of Service </a>
        </Modal.Body>
        <Modal.Footer>
         
       <span style={{ padding: '10px',fontWeight: 600
    }}>By clicking 'I Accept' you are agreeing to our terms and conditions.</span> 
        <LoadingButton 
        variant="contained" 
        color="primary" 
        loading={loader}
        style={{
          background: "#88AC2F"
          
        }}
        onClick={onAccept}
      >
         I Accept
      
        
      </LoadingButton>
{/* 
          <Button onClick={onAccept} appearance="primary">
           I Accept
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Disclaimer;
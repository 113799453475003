import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Group, Menu, UnstyledButton, createStyles, Text} from "@mantine/core";
import { IconChevronDown, IconHeart, IconStar, IconMessage,IconHelp,IconHome2, IconSettings, IconTableOptions, IconLogout, IconVideo,IconPlayerPause, IconTrash } from "@tabler/icons";
import { useState } from "react";
import TourIcon from '@mui/icons-material/Tour';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import posthog from 'posthog-js';
import { useSessionManager } from "../SessionManagerProvider";

// import history from "../History";

const useStyles = createStyles((theme) => ({
    header: {
      paddingTop: theme.spacing.sm,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]
      }`,
      marginBottom: 120,
    },
  
    mainSection: {
      paddingBottom: theme.spacing.sm,
    },
  
    user: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      transition: 'background-color 100ms ease',
  
      // '&:hover': {
      //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
      // },
  
      [theme.fn.smallerThan('xs')]: {
        width:'unset'
      },
    },
  
    burger: {
      [theme.fn.largerThan('xs')]: {
        display: 'none',
      },
    },
  
    // userActive: {
    //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    // },
  
    tabs: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  
    tabsList: {
      borderBottom: '0 !important',
    },
  
    tab: {
      fontWeight: 500,
      height: 38,
      backgroundColor: 'transparent',
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      },
  
      '&[data-active]': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      },
    },
  }));

  
export const  UserMenu = () => {
    const { classes, theme, cx } = useStyles();
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const history = useHistory();
    const {runTourDashboard, setRunTourDashboard} = useSessionManager();


    const {
      user,
      logout,
    } = useAuth0();


    const logoutWithRedirect = () => {
      logout({ logoutParams: { returnTo: window.location.origin } })
    }
 



    return(
        <div>
        <Menu
        width={260}
        position="bottom-end"
        transition="pop-top-right"
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
      >
        <Menu.Target>
          <UnstyledButton
            className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
          >
            <Group spacing={7}>
              <Avatar src={user.picture} alt={user.name} radius="xl" size={20} />
              <Text weight={500} size="sm" sx={{ lineHeight: 1,color:'white' }} mr={3}>
                {user.name} 
              </Text>
              <IconChevronDown size={12} color="white" stroke={1.5} />
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
        {/* <Menu.Item onClick={() => history.push('/home')} icon={<IconHome2 size={14} stroke={1.5} />}>Home</Menu.Item> */}
          
          <Menu.Item onClick={() => history.push('/profile')} icon={<IconSettings size={14} stroke={1.5} />}>My Account</Menu.Item>
          <Menu.Item onClick={() => {
            posthog.capture("Book a demo");
            window.open("https://www.adeptia.com/adeptia-connect-demo")
          }} icon={<IconVideo size={14} stroke={1.5} />}>Book a demo</Menu.Item>
          {/* <Menu.Item onClick={() =>history.push('/faq')} icon={<IconHelp size={14} stroke={1.5} />}>Faq</Menu.Item> */}
          {/* <Menu.Item onClick={() => {setRunTourDashboard(true)}} icon={<TourIcon size={12} stroke={1.5} />}>Start Tour</Menu.Item> */}
          <Menu.Item onClick={() =>history.push('/termsCondition')} icon={<IconTableOptions size={14} stroke={1.5} />}>Terms & Conditions</Menu.Item>
          <Menu.Item onClick={() => logoutWithRedirect()} icon={<IconLogout size={14} stroke={1.5} />}>Logout</Menu.Item>

        </Menu.Dropdown>
      </Menu>
      </div>
    )
};
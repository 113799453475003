import { ParentContextProvider } from './context/ParentContext';
import EvaluateWrapper from './Wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';

function ContextEvalauteWrapper() {
  return (
  <ParentContextProvider>
<EvaluateWrapper />
  </ParentContextProvider>
  );
}

export default ContextEvalauteWrapper;
